@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/legal_entities';

.infoWarningBox {
  background-color: $color-warning;
  color: black;
  margin-bottom: 30px;
  padding: 16px 12px 24px 16px;

  &-le {
    @include legalEntityWarningBoxStyling();
  }

  .warningIcon {
    padding-right: 12px;
  }

  .warningEntry {
    padding-top: 4px;
    display: flex;
    justify-content: flex-start;
  }
}
