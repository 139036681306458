@import "../../../sass/base/legal_entities";

.licenses-headerBox {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &-title-le {
    @include legalEntityPageTitleStyling();
  }
  &-subtitle-le {
    @include legalEntityPageSubtitleStyling();
  }

  .buttonIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    padding: 6px;
    border-radius: 2px;
  }

  p {
    margin-left: 8px;
  }
}
