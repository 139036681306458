@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/legal_entities";
@import "src/portal/sass/base/typography";

.filterBox {
  margin-top: 32px;
  margin-bottom: 24px;
  padding: 24px 24px 32px;
  &-boxStyling-le {
    @include legalEntityComponentSecondaryStyling();
    @include legalEntityIconsPrimaryFontColorStyling();
  }

  .titleField {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      &.filterBox-title-le {
        @include legalEntitySearchBoxStyling();
      }
    }
  }

  .inputField {
    position: relative;

    &-le {
      @each $le in $legal_entities {
        &--#{$le} {
          @if($le) == 'dts' {
            &::placeholder {
              color: $color_primary_dts !important;
            }
          }
        }
      }

    }

    input {
      height: 36px;
      width: 100%;
      box-sizing: border-box;
      margin: 40px 0 0;
      padding: 6px 8px 6px 12px;
      font-size: $fontsize_medium;
      font-weight: normal;


    }

    .iconButton {
      position: absolute;
      top: 4px;
      right: 8px;
      background-color: unset;
      padding: unset;
      box-sizing: border-box;

      &-le--nfon {
        path {
          fill: $color_white !important;
        }
      }
    }

  }

  .input {
    &-le {
      @include legalEntityLangSelectorStyling();
    }
  }
}
