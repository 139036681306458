@import 'src/portal/sass/base/dimensions';

.helpBox {
  display: inline-block;

  vertical-align: text-bottom;

  svg {
    margin-left: 8px;
  }
}
