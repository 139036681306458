@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/dimensions";
@import "src/portal/sass/base/legal_entities";

.billAccount {
  @include box;
  @include card;

  .componentTitle {
    margin-bottom: 48px;
  }

  h5 {
    @include hasIcon;
    display: flex;
    align-items: center;
  }

  .switch {
    display: inline-block;
    margin-right: $width_toggleIconSpace;
  }

  .billAddress {
    margin-top: 1rem;
  }

  .columnContainer {
    display: flex;
    justify-content: space-between;

    &:first-child {
      align-items: baseline;
    }

    .column {
      flex-basis: calc(33% - (#{$width_marginDefault} * 2 / 3));

      h4:not(:first-child) {
        margin-top: $height_mediumSmallSpace;
        margin-bottom: $height_smallSpace;
      }

      &-item:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    .columnTitle {
      margin-bottom: .5rem;

     .iconOnly {
       margin-left: 1rem;
     }

      &-le--dts {
        color: var(--primary-color);
      }
    }
  }
}

.formattedIban {
  background: none;
  color: inherit;
  border: 0;
  width: 100%;
  padding: 0;
  cursor: text;
}

.editBillAccountModal {
  .subtitle {
    margin: 2rem 0 1rem 0;
  }
  .errorField {
    margin-bottom: 64px;
    color: red;
  }

  .twoButton {
    margin-top: 3rem !important;
  }
}