@import 'src/portal/sass/base/legal_entities';

.detailBox {
  width: 163.3px;
  display: flex;

  svg {
    g, path {
      fill: var(--card-title-color);
    }
  }

  .subtext {
    p {
      overflow-wrap: anywhere;
    }
  }


  &__textbox {
    margin-left: 8px;
    display: flex;
    justify-content: space-between;
    width: inherit;

    &-le {
      @include textDetailBoxStyling();
    }

    h5.label {
      margin-bottom: .5rem;
    }
  }

  &__withoutLeftIcon {
    display: flex;
    justify-content: space-between;
    width: inherit;

    &-le {
      @include textDetailBoxStyling();
    }
  }

  &:nth-child(-n + 2) {
    margin-right: 64px;
  }
}
