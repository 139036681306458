@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.billingInformation {

  &-ibanInputGroup {

    .input-helpBox {
      display: flex;
      gap: .5rem;
      align-items: center;
    }

  }

  &-container {
    border-bottom: 1px solid $color_box_border_nfon;
    padding-bottom: 32px;
    margin-bottom: 32px;
  }

  &-switch {

    &--wrapper {
      font-size: 16px;
      display: flex;
      gap: 16px;
      align-items: baseline;
    }
  }

  &-collapsible {
    margin-top: 32px;
  }
}