@import "../../../../../../portal/sass/base/colors";
@import "../../../../../../portal/sass/base/legal_entities";

.orderCallNumberPageTwo {

  &-section-title {
    padding: 1rem 0;
  }

  &-modalSubTitle {
    margin-bottom: 2rem !important;
  }

  &-hr {
    margin-top: 2em;

    &-le {
      @include legalEntityLineColorDark();
    }
  }

  &-alert {
    color: $color_error;
    margin-bottom: 6px;
  }
}