@import "../../../sass/base/common";
@import "../../../sass/base/colors";
@import "../../../sass/base/legal_entities";

.tableBox {
  @include box;
  @include card;

  .sitesTable {
    padding: 0;
    .paginatedTable {
      padding-top: 0;
    }
    border: none;

    .name p {
      font-weight: 500;
    }
  }
}

.myData {
  &-title-le {
    @include legalEntityPageTitleStyling();
  }

  &-subtitle-le {
    @include legalEntityPageSubtitleStyling();
  }
}

.paginatedTable {
  padding-left: 0;
  padding-right: 0;
}
