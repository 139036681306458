@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.documentsTable {
  @include box;

  td {
    &:nth-child(1) {
      width: 40% !important;
    }

    &:nth-child(2) {
      width: 45% !important;
    }

  }

  &-le {
    @include legalEntityComponentStyling();
  }

  &-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    &-le {
      @include legalEntityStatusIconPathStyling();
    }
  }

  &-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;

    .buttonIcons {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 36px;
      padding-left: 6px;

      p {
        margin-left: 8px;
      }
    }

  }

  &-title-le {
    @include legalEntityHeadingStylingH4();
  }

  .tableFirstChild {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &-font-le {
      @include legalEntityTableFirstChildFontColor();
      @include legalEntityBodyFontSize();
    }
  }

  &-subLabel {
    display: block;
    font: var(--typo-body-small);

    &-le {
      @include legalEntitySubLabelColor();
    }
  }

  .paginatedTable {
    padding-top: 0;
  }

}

.proofOfLocationModal {
  &-wrapper {
    margin: 2rem 0;
  }
}