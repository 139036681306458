@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/legal_entities';

.orderOverviewBasket {
  @include box;

  .step-subtitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &-product-name {
    display: flex;
    justify-content: space-between;
  }

  &.boxStyling {
    @include card;
  }

  &-le {
    @include legalEntityOrderOverviewBasketStyling();
  }

  table {
    border-collapse: collapse;

    .boxHeader {
      &-le {
        @include legalEntityBoxHeaderStyling();
      }
    }

    tbody {
      th {
        font-size: 14px;
        font-weight: 300;
        text-align: left;
      }

      td {
        @include hasIcon;

        padding: calc(#{$width_paddingActiveTableRow} / 2) !important;

        &:nth-child(1) {
          width: 50% !important;
        }

        &:nth-child(2) {
          width: 20% !important;
        }

        &:nth-child(3) {
          width: 15% !important;
        }

        &:nth-child(4) {
          width: 15% !important;
        }
      }

      td.line {
        border-top: 1px solid $color_box_border_nfon;
      }
      td.centered-text {
        text-align: center;
      }

      th.product-quantity, td.product-quantity {
        text-align: center;
      }

      tr.second-hierarchy {
        border-left: 0.5em solid transparent;
      }

      tr.third-hierarchy {
        border-left: 1.5em solid transparent;

        td {
          border: none;
        }

      }

      tr > td.basket-address {
        font-size: $fontsize_small;
        font-weight: 100;
        padding-left: 3rem !important;
        border: none;
      }
    }

  }

  tr.sum {
    td {
      &:nth-child(3), &:nth-child(4) {
        width: 15% !important;
        text-align: center;
      }
    }
  }
}