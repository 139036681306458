@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.personInCharge {

  .break-anywhere {
    overflow-wrap: anywhere;
  }

  .aColor {
    overflow-wrap: anywhere;
    color: var(--primary-color);
  }
}
