@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.step {
  @include box;
  background: $color_white;

  &-le {
    @include legalEntityComponentStyling();
  }

  &-title-le {
    @include legalEntityPageTitleStyling();
  }
  h4 {
    margin-bottom: $height_bigSpace;
  }

  &-subtitle-le {
    @include legalEntityCustomerDataLabelStyling();
    @include legalEntityHeadingStylingH4();
  }
  input, .dropdown {
    max-width: $width_inputMax;
    width: $width_inputMax;
  }
  button {
    margin-left: auto;
    display: block;
  }

  .step-title-wrapper {
    display: flex;
    align-items: baseline;
  }

}

.boxes {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1em 1em;
  margin-bottom: 15px;

  hr {
    margin-bottom: 16px;
  }

  .box-item {
    display: flex;
    list-style: none;
    margin-bottom: 16px;

    svg {
      min-width: 24px;
    }
    li {
      margin-left: 8px;
    }
  }

}

.options {
  &-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
  }

  .addSipTrunk-button {
    display: flex;
    align-items: center;
    gap: .5rem;
  }
}

.connections {
  &-title {
    margin-bottom: 1rem;
  }
}

.customerContactLanguage {
  margin-top: .5rem;
}