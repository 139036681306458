@import "../../../../../../sass/base/dimensions";
@import "../../../../../../sass/base/colors";
@import "../../../../../../sass/base/legal_entities";

.portingNumberSwitch {

  &--switch {
    width: 315px;
    margin-bottom: 1rem;

    &.switch-en {
      width: 360px;
    }

    .switch-text {
      &-le {
        &::before {
          width: 320px;
        }

        &::after {
          width: 175px;
        }

        &.active {
          &::after {
            width: 150px;
            left: 320px - 150px;
          }
        }

        &.en {
          width: 360px;

          &::before {
            width: 360px;
          }

          &::after {
            width: 250px;
            transition: all 0.2s ease-in-out;
          }

          &.active {
            &::after {
              width: 115px;
              left: 360px - 115px;
            }
          }
        }
      }

    }
  }

  .portingIndividualNumberForm {
    &-section {
      display: flex;
      gap: 4.5rem;

      .localAreaCode {
        width: 113px;

        label {
          width: 115px;
        }

        .error-msg {
          display: block;
          width: 10rem;
        }
      }
    }

    &-checkbox {
      margin-top: 1rem;
    }
  }
  .portingNumberBlockForm {
    &-section {
      display: flex;
      gap: 4.5rem;

      .localAreaCode {
        width: 113px;

        label {
          width: 115px;
        }

        .error-msg {
          display: block;
          width: 10rem;
        }
      }
    }

    &-checkbox {
      margin-top: 1rem;
    }

    &-container {
      display: flex;
      gap: 2rem;
      align-items: center;

      .helpBox {
        .popup {
          bottom: 2.5rem;
          left: 0rem;
          min-width: 17rem;
          overflow: hidden;
        }
      }
    }

    &-icons {
      display: flex;
      gap: 1.5rem;
      align-items: start;

      path {
        color: red;
      }

      &-le {
        @include legalEntityStatusIconPathStyling();
      }
    }

    &-input {
      width: 408px;
    }

  }
}

.portingCallNumberConfiguration {
  &-container {
    display: flex;
    gap: 2rem;
    align-items: center;

    .helpBox {
      .popup {
        bottom: 2.5rem;
        left: 0rem;
        min-width: 17rem;
        overflow: hidden;
      }
    }
  }

  &-input {
    width: 417px;
  }

  &-icons {
    display: flex;
    gap: 1.5rem;
    align-items: start;

    path {
      color: red;
    }

    &-le {
      @include legalEntityStatusIconPathStyling();
    }
  }
}