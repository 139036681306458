@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/legal_entities";

.myDataOverview {
  @include box;
  @include primaryBackground;


  &-boxStyling-le {
    @include legalEntityComponentSecondaryStyling();
    @include legalEntityIconsPrimaryFontColorStyling();
    @include legalEntityBodyFontSize();
  }

  .subheading {
    margin-bottom: $height_mediumSpace;

    &-content-le {
      @include legalEntityCustomerDataOverviewFontStyling();
    }
  }

  &-title-le {
    @include legalEntityHeadingStylingH4();
  }

  .idStatus {
    display: flex;
    margin: 24px 0;

    div {
      &:first-child {
        margin-right: 32px;
      }
    }

  }

  .content-le {
    @include legalEntityCustomerDataOverviewFontStyling();
  }

  .columnContainer {
    display: flex;
    justify-content: space-between;

    .column {
      flex-basis: calc(50% - (#{$width_marginDefault} / 2));

      &:first-child {
        .logo {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: white;
          margin-right: 40px;


          svg {
            color: black;
            width: 50%;
          }
        }
      }

      .mail {
        line-break: anywhere;
      }
    }
  }

  h4 {
    @include hasIcon;

    &:not(:first-child) {
      margin-top: $height_mediumSpace;
    }
  }
}
