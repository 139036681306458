.noMargin {
  margin: 0;
}

.passwordCriteriaResetPWPage {
  max-width: 100%;
}

.hideButtonResetPWPage {
  right: unset;
}
