@import "../../portal/sass/base/colors";
@import "../../portal/sass/base/typography";
@import "../../portal/sass/base/dimensions";
@import 'src/portal/sass/base/legal_entities';


.addressInputGroup {
  display: flex;
  align-items: start;
  gap: 16px;

  .helpBox {
    height: 20px;
  }


  .helpBox-icon {
    height: 20px;
  }

  .input-helpBox {
    display: flex;
    gap: .5rem;
    align-items: center;
  }

  &-container {
    margin-bottom: 1rem;
    position: relative;
    padding: 0;
    box-sizing: border-box;

    label {
      line-height: 1.25rem;
      font-size: $fontsize_small;
      margin-bottom: 4px;
      display: block;
    }

    .label {
      &-le {
        @include legalEntityLoginLabelStyling();
      }
    }

    #connectionAddress\.companyName1,
    #connectionAddress\.companyName2,
    #connectionAddress\.street,
    #connectionAddress\.houseNumber,
    #connectionAddress\.country\.id,
    #connectionAddress\.currentProviders,
    #connectionAddress\.oldCustomerNr,
    #currentCarrierNonDe,
    #oldCustomerNr
    {
      width: 408px;
    }

    #connectionAddress\.zip {
      width: 115px;
    }

    #connectionAddress\.city {
      width: 277px;
    }

    #mainAddress\.street,
    #account\.billAddress\.street,
    #siteAddress\.street {
      width: 362px;
    }

    .input-block {
      width: 100% !important;
    }

    #mainAddress\.houseNumber,
    #account\.billAddress\.houseNumber {
      width: 102px;
    }

    #siteAddress\.city {
      flex-grow: 4;
    }

    .label-houseNumber {
      margin-bottom: 8px;
    }

    #mainAddress\.zip,
    #account\.billAddress\.zip,
     {
      width: 140px;
    }

    #mainAddress\.city,
    #account\.billAddress\.city {
      width: 324px;
    }

    #mainAddress\.city2,
    #account\.billAddress\.city2 {
      width: 140px;
    }

    #mainAddress\.district,
    #account\.billAddress\.district {
      width: 324px;
    }

    #siteAddress\.district {
      width: 324px;
    }

    #mainAddress\.city3,
    #account\.billAddress\.city3,
    #siteAddress\.city3 {
      width: 100%;
    }

    #siteAddress\.city{
      width: 400px
    }

    #siteAddress\.city2 {
      width: 100%;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    .input {
      font-size: 16px;
      display: inline-block;
      box-sizing: border-box;
      line-height: 1.4;
      padding: $height_inputPadding $width_inputPadding;
      font-family: inherit;
      font-weight: inherit;


      &-le {
        @include legalEntityInputStyling();
      }

      &:required ~ label::after {
        content: " *";
      }
    }
  }
}
