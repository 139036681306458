.contactDataItem {

  td {
    border: none !important;
    padding-bottom: 0;
  }

  td:nth-child(1) {
    width: 100%;
  }

  td:nth-child(2) {
    width: 10%;
    vertical-align: inherit;
    padding-left: 1rem;
  }

  td:nth-child(3) {
    vertical-align: inherit;
    padding-left: 1.75rem;
  }

  .inputWithLabel {
    width: 100%;
  }

  &-switches {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    table {
      width: 100%;
    }
  }
}

.updateContactData {

  &-table {
    &-header {
      font-size: 14px;
      text-align: center;
    }
  }

  #countryCode {
    width: 150px;
    margin-right: 8px;
  }

  #areaCode {
    margin: 0 8px;
    width: 234px;
  }

  #cli {
    width: 425px;
    margin-left: 8px;
  }
}

