@import "src/portal/sass/base/legal_entities";


.yourPartner {

  input[type=number] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &-servicePin {
    border-top: 1px solid white;

    &-title {
      margin-top: 0 !important;
      font-weight: 700;
    }

    &-wrapper {
      margin-top: 1rem;
      display: flex;
      gap: .5rem;
      align-items: center;

      .iconOnly {
        color: white;
      }
    }

    &-input {
      font-size: 36px;
      border: none;
      color: white;
      padding: .25rem .5rem 0 .5rem;
      width: 50%;
      font: var(--typo-h3);
      font-weight: 700;
      background: #2502a9;

      &-le--dts {
        background: #014673 !important;
      }

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: white;
        opacity: .5;
      }
    }

    &-pin {
      margin: 0;
      padding: 0;

      .iconOnly {
        color: white;
      }

      .pin {
        display: flex;
        gap: .5rem;

        h3 {
          font-weight: 700;
        }

        .cpxButton svg path {
          fill: white;
        }
      }

      .edit-pin {
        margin: .5rem 0 0 -.1rem;

        #servicePin-form {
          display: flex;
          align-items: center;
          gap: .5rem;
          justify-content: space-between;
        }
      }

      .alert div {
        font-size: 14px;
      }
    }

    &-emptystate {
      button {
        margin-top: .5rem;
      }
    }
  }
}

.mail {
  line-break: anywhere;
}