@import 'src/portal/sass/base/common';
@import '../../../sass/base/common';
@import '../../../sass/base/colors';
@import '../../../sass/base/legal_entities';

.partner-customers {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.buttonIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  padding-left: 6px;

  p {
    margin-left: 8px;
  }
}
.buttonGroup {
  display: flex;
  gap: 1rem;
}

.customersTitle {
  &-le {
    @include legalEntityTitleColorStyling();
  }
}

.partner-customers {

  &-title-le {
    @include legalEntityPageTitleStyling();
  }
}

.customers {
  @include box;
  @include card;
}

#customersTable {
  td {
    @include hasIcon;

    &:nth-child(1) {
      width: 33% !important;
    }

    &:nth-child(2) {
      width: 20% !important;
    }

    &:nth-child(3) {
      width: 17% !important;
    }

    &:nth-child(4) {
      width: 20% !important;
    }

    &:nth-child(5) {
      width: 25% !important;
    }
  }

  .tableFirstChild {
    .partnerCustomerButton {
      background: unset;
      padding: unset;
      text-align: unset;
      text-transform: unset;
      -moz-user-select: all; /* Firefox */
      cursor: pointer;

      &-font-le {
        @include legalEntityTableFirstChildFontColor();
        @each $le in $legal_entities {
          &--#{$le}:hover:not(:disabled) {
            color: getLegalEntitySecondaryHoverColor($le);
          }
        }
      }
    }
  }
}

.partnerCustomersTable-icon-le {
  @include legalEntitySecondaryIconStyling();
}
