@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.configurationBox {

  padding: 24px;
  margin-bottom: 1rem;

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &-le {
    @include legalEntityNavigationStyling();
  }


  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    button:first-child {
      margin-right: 15px ;
    }
  }
}