@import "../../portal/sass/base/typography";
@import "../../portal/sass/base/dimensions";
@import "../../portal/sass/base/legal_entities";

.dropdownWithLabel {
  margin-bottom: 1rem;
  position: relative;
  padding: 0;
  box-sizing: border-box;

  label {
    line-height: 1.25rem;
    font-size: $fontsize_small;
    margin-bottom: 4px;
    display: block;
  }

  .label {
    &-le {
      @include legalEntityLoginLabelStyling();
    }
  }

  .required {
    &::after {
      content: " *";
    }
  }

  .dropdown {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .select {
      display: block;
      width: 100%;
      border: 1px solid $color_inputBorder;
      box-sizing: border-box;
      line-height: 1.4;
      padding: $height_inputPadding $width_inputPadding;
      color: inherit;
      background: inherit;

      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;

      &:required ~ label::after {
        content: " *";
      }

      &-le {
        @include legalEntityInputStyling();
      }

    }

    svg {
      position: absolute;
      padding-right: 6px;
      pointer-events: none;
    }
  }

  .error {
    &-le {
      @include legalEntityInputBorderErrorStyling();
    }
  }
}
