@import '../../../../../sass/base/common';
@import '../../../../../sass/base/colors';
@import '../../../../../sass/base/dimensions';
@import '../../../../../sass/base/legal_entities';

.personInChargeList {
  @include box;
  @include card;


  h3,
  h4 {
    display: flex;
    align-items: center;
    margin-bottom: $height_mediumSpace;

    &.componentTitle {
      margin-bottom: 2rem !important;
      &-le {
        @include legalEntityCustomerDataLabelStyling();
      }
    }
  }

  a {
    text-decoration: none;
  }
}
