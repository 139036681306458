@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/legal_entities';

.warning-box {

  &-le {
    @include legalEntityWarningBoxStyling();
  }
  margin-top: 0px;
  margin-bottom: 24px;
  padding: 16px 12px 16px 16px;

  .warning-icon {
    padding-right: 12px;
  }

  .warning-entry {
    padding-top: 4px;
    display: flex;
    justify-content: flex-start;
  }
}
