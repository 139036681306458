@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/legal_entities';

.customerDataOverview {
  @include box;
  @include primaryBackground;

  &-boxStyling-le {
    @include legalEntityComponentSecondaryStyling();
    @include legalEntityIconsPrimaryFontColorStyling();
  }

  .content {
    overflow-wrap: anywhere;
    &-le {
      @include legalEntityCustomerDataOverviewFontStyling();
    }
  }

  &-companyName {
    overflow-wrap: anywhere;
  }

  .column-container {
    display: flex;
    justify-content: space-between;

    .column {
      .customer-data-heading {
        font-size: 22px;
        margin-bottom: 25px;
        line-height: 1.5;
      }

      .inner-column-container {
        display: flex;

        .inner-column {
          min-width: 200px;

          div {
            &:first-child {
              margin-bottom: 35px;
            }

            h5 {
              line-height: 24px;
              display: flex;
              margin-bottom: 4px;
            }
          }

          div {
            .subheading-content-le {
              @include legalEntityCustomerDataOverviewFontStyling();
            }

            .symbol-text-spacing {
              margin-left: $marginSymbolText;
            }
            .mail {
              line-break: anywhere;
            }
          }

          &:nth-child(2) {
            margin-left: 30px;

            .customer-status {
              display: flex;
              align-items: center;

              .symbol-text {
                margin-left: $marginSymbolText;
              }
            }
          }
        }
      }

      &:nth-child(2) {
        .picture {
          display: flex;
          justify-content: center;
          width: 150px;
          height: 150px;
          margin-left: auto;
          background-color: white;
          padding: 5px;

          svg {
            color: black;
            width: 100px;
          }
        }
      }
    }
  }

  .bottom-row {
    padding-top: 4px;
    text-align: right;
  }
}
