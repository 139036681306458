@import '../../sass/base/colors';
@import '../../sass/base/fonts';
@import '../../sass/base/dimensions';
@import '../../sass/base/typography';


$marginSymbolText: 7px;

$legal_entities: nfon, dts, tef;

@function getLegalEntityModalPrimaryColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_primary_dts;
  } @else {
    @return $color_blueGrey;
  }
}

@function getLegalEntityBrandColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_yellow_dts;
  } @else {
    @return $color_primary_nfon;
  }
}

@function getLegalEntitySelectedBoxColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return var(--highlight-color);
  } @else {
    @return var(--bg-contrast-color);
  }
}

@function getLegalEntitySelectedBoxBorderColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return var(--highlight-color);
  } @else {
    @return var(--primary-color);
  }
}

@function getLegalEntitySelectedTextColor($legal_entity) {
  @return var(--primary-color);
}

@function getLegalEntityCompColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_white;
  } @else {
    @return $color_bg_grey_nfon;
  }
}

@function getLegalEntityModalColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_white;
  } @else {
    @return $color_black;
  }
}

@function getLegalEntityCompBorderColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_white;
  } @else {
    @return var(--card-border-color);
  }
}

@function getLegalEntityPrimaryContrastColor($legal_entity) {
  @return var(--bg-contrast-color);
}

@function getLegalEntityPageBackgroundColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_pageBackground_dts;
  } @else {
    @return $color_bg_grey_nfon/*linear-gradient(179.88deg, rgba(255, 255, 255, 0.2) 6.25%, rgba(255, 255, 255, 0.09) 99.89%), #000000*/;
  }
}

@function getLegalEntityFigureBoxBackgroundColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_yellow_dts;
  } @else {
    @return $color_bg_grey_nfon;
  }
}

@function getLegalEntityStepperFooterBackgroundColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return var(--primary-color);
  } @else {
    @return var(--bg-contrast-color);
  }
}

@function getLegalEntityFigureBoxColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_font_dark_dts;
  } @else {
    @return $color_font_nfon;
  }
}

@function getLegalEntityPrimaryFontColor($legal_entity) {
  @return var(--text-color)
}


@function getLegalEntityHelpBoxIconColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_darkGrey;
  } @else {
    @return $color_switchOff_nfon;
  }
}


@function getLegalEntityErrorColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_error;
  } @else {
    @return $color_error_nfon;
  }
}

@function getLegalEntityHelpBoxColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_darkGrey;
  } @else {
    @return $color_white;
  }
}

@function getLegalEntityUserNavigationPrefUserColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return var(--text-color);
  } @else {
    @return var(--neutral-color);
  }
}

@function getLegalEntityHelpBoxIconHoverColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_primaryHover_dts;
  } @else {
    @return $color_neutral;
  }
}

@function getLegalEntityPrimaryLinkColor($legal_entity) {
  @return var(--primary-color);
}

@function getLegalEntityContrastLinkColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return var(--primary-color);
  } @else {
    @return var(--color-white);
  }
}

@function getLegalEntityIconColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_primary_dts;
  } @else {
    @return $color_secondary_nfon;
  }
}

@function getLegalEntitySecondaryIconColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_black;
  } @else {
    @return $color_secondary_nfon;
  }
}

@function getLegalEntityUserIconColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_secondary_nfon;
  } @else {
    @return $color_white_nfon;
  }
}

@function getLegalEntityProgressbarColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return var(--success-color)
  } @else {
    @return var(--primary-color);
  }
}

@function getLegalEntityProgressbarBackgroundColor($legal_entity) {
  @return var(--neutral-color);
}

@function getLegalEntityModalBoxInputBorderColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_boxBorder_dts;
  } @else {
    @return $color_input_border_nfon;
  }
}

@function getLegalEntityInputBorderColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_boxBorder_dts;
  } @else {
    @return $color_input_border_nfon;
  }
}

@function getLegalEntityInputBorderRadius($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $dimension_checkboxBorderRadius;
  } @else {
    @return null;
  }
}

@function getLegalEntityInputFocus($legal_entity) {
  @if $legal_entity == 'dts' {
    @return 1px solid $color_primary_dts;
  } @else {
    @return 1px solid $color_white;
  }
}

@function getLegalEntityInputLineHeight($legal_entity) {
  @if $legal_entity == 'dts' {
    @return 1.11;
  } @else {
    @return 20px;
  }
}

@function getLegalEntityInputPadding($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $height_inputPadding $width_inputPadding;
  } @else {
    @return 4px 12px 6px 12px;
  }
}

@function getLegalEntityInputBGColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return null;
  } @else {
    @return $color_input_bg_nfon;
  }
}

@function getLegalEntityInputColorColor($legal_entity) {
  @return var(--card-title-color);
}

@function getLegalEntityInputPlaceholderColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return null;
  } @else {
    @return $color_switchOff_nfon;
  }
}

@function getLegalEntityModalBoxColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return null;
  } @else {
    @return $color_white;
  }
}

@function getLegalEntityModalBoxIconColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return var(--primary-color);
  } @else {
    @return var(--color-white);
  }
}

@function getLegalEntityModalSummaryInfoFontIconColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_primary_dts;
  } @else {
    @return $color_white_nfon;
  }
}

@function bottomLinkColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_primary_dts;
  } @else {
    @return inherit;
  }
}

/*########### Button Colors ###########*/
@function getLegalEntityPrimaryHoverColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return var(--button-bg-hover-color);
  } @else {
    @return var(--button-bg-hover-color);
  }
}

@function getLegalEntitySecondaryHoverColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_secondaryHover_dts;
  } @else {
    @return $color_button_hover_nfon;
  }
}

@function getLegalEntityPrimaryDisabledBackground($legal_entity) {
  @if $legal_entity == 'dts' {
    @return #9ab7c1;
  } @else {
    @return linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #000000;
  }
}

@function getLegalEntityDisabledColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return var(--button-disabled-bg-color);
  } @else {
    @return var(--neutral-color);
  }
}

@function getLegalEntityDisabledBackgroundColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_lightBlueGrey;
  } @else {
    @return $color_bg_grey_nfon;
  }
}

@function getLegalEntityButtonSecondaryColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_button_secondary_dts;
  } @else {
    @return $color_secondary_nfon;
  }
}

@function getLegalEntityEditButtonColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_white;
  } @else {
    @return $color_button_secondary_nfon;
  }
}

@function getLegalEntityEditButtonBGColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_primary_dts;
  } @else {
    @return unset;
  }
}

/*########### Page Title Colors ###########*/
@function getLegalEntityPageTitleColor($legal_entity) {
  @return var(--title-color);
}

@function getLegalEntityPageSubtitleColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_font_dark_dts;
  } @else {
    @return $color_font_nfon;
  }
}

@function getLegalEntityLoginLabelColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_font_dark_dts;
  } @else {
    @return $color_white_nfon;
  }
}

@function getLegalEntityModalBoxInput($legal_entity) {
  @if $legal_entity == 'dts' {
    @return var(--color-primary);
  } @else {
    @return var(--color-white);
  }
}

@function getLegalEntityNavFontColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_black;
  } @else {
    @return $color_black;
  }
}

@function getLegalEntityPrimarySwitchColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return var(--primary-color);
  } @else {
    @return inherit;
  }
}

@function getDTSPrimaryColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_primary_dts;
  } @else {
    @return null;
  }
}

@function getLegalEntitySwitchHoverColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_switchHover_dts;
  } @else {
    @return $color_switchHover_nfon;
  }
}

@function getLegalEntitySwitchOffColor($legal_entity) {
  @return var(--neutral-color);
}

@function getLegalEntitySwitchBGColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return null;
  } @else {
    @return var(--color-white);
  }
}

@function getLegalEntityDarkColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_primary_dts;
  } @else {
    @return $color_white;
  }
}

@function getLegalEntityBoxBorder($legal_entity) {
  @if $legal_entity == 'dts' {
    @return 0px;
  } @else {
    @return 1px solid $color_box_border_nfon;
  }
}

@function getLegalEntityContextColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_darkGrey;
  } @else {
    @return $color_white_nfon;
  }
}

@function getLegalEntityContractPersonColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_primary_dts;
  } @else {
    @return $color_white;
  }
}

@function getLegalEntityFooterColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return inherit;
  } @else {
    @return $color_switchOff_nfon;
  }
}

@function getLegalEntityTableLines($legal_entity) {
  @if $legal_entity == 'dts' {
    @return var(--bg-color);
  } @else {
    @return var(--card-border-color);
  }
}

@function getLegalEntityMonthlyTableLines($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_tableLines_dts;
  } @else {
    @return $color_white;
  }
}


@function getLegalEntityTableFirstLine($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_tableFirstLine_dts;
  } @else {
    @return $color_tableFirstLine_nfon;
  }
}

@function getLegalEntityDarkIconOpacity($legal_entity) {
  @if $legal_entity == 'dts' {
    @return 1;
  } @else {
    @return 0.5;
  }
}

@function getLegalEntityFontFamily($legal_entity) {
  @if $legal_entity == 'dts' {
    @return 'FiraSans-Regular', sans-serif;
  } @else {
    @return 'PPPangramSans-Regular', sans-serif;
  }
}

@function getLegalEntityTableFirstChildFontColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_tableFirstChild_dts;
  } @else {
    @return $color_tableFirstChild_nfon;
  }
}

@function getLegalEntityMoreLinkFont($legal_entity) {
  @if $legal_entity == 'dts' {
    @return 500;
  } @else {
    @return 700;
  }
}

@function getLegalEntityModalTitleColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_primary_dts;
  } @else {
    @return $color_white_nfon;
  }
}

@function getLegalEntityProgressbarFontColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_darkGrey;
  } @else {
    @return $color_white_nfon;
  }
}

@function getLegalEntityTableHeaderFontFamily($legal_entity) {
  @if $legal_entity == 'dts' {
    @return 500;
  } @else {
    @return 700;
  }
}

@function getLegalEntityCustomerBoxFontColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return var(--primary-color);
  } @else {
    @return var(--color-white);
  }
}

@function getLegalEntityLineColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_lineColor_dts;
  } @else {
    @return $color_lineColor_nfon;
  }
}

@function getLegalEntityLineColorDark($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_lineColor_dark_dts;
  } @else {
    @return $color_white;
  }
}

@function getLegalEntityTableHeaderFontColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_darkGrey;
  } @else {
    @return $color_white;
  }
}

@function getLegalEntityTableChildFontColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_tableSecondToLastChild_dts;
  } @else {
    @return $color_tableSecondToLastChild_nfon;
  }
}

@function getLegalEntityUserInfoCustomerNameFontColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_font_highlight_dts;
  } @else {
    @return $color_font_nfon;
  }
}

@function getLegalEntityPaginationColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_darkGrey;
  } @else {
    @return $color_secondary_nfon;
  }
}

@function getLegalEntityPaginationActiveColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_primary_dts;
  } @else {
    @return $color_white_nfon;
  }
}

@function getLegalEntityPaginationSizeSelectColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_darkGrey;
  } @else {
    @return $color_white_nfon;
  }
}

@function getLegalEntityPaginationSelectColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_primary_dts;
  } @else {
    @return $color_white_nfon;
  }
}

@function getLegalEntityStatusColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_neutral;
  } @else {
    @return $color_white;
  }
}

@function getLegalEntityActiveFontColor($legal_entity) {
  @return var(--primary-color);
}

@function getLegalEntityActiveBackgroundColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_lightGrey;
  } @else {
    @return null;
  }
}

@function getLegalEntityActiveButtonColor($legal_entity) {
  @return var(--button-active-bg-color);
}

@function getLegalEntityUserInfoCustomerNameFontColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_font_highlight_dts;
  } @else {
    @return $color_font_nfon;
  }
}

@function getLegalEntityAvatarBackground($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_yellow_dts;
  } @else {
    @return $color_avatarBG_Nfon;
  }
}

/*########### Logo Dimensions ###########*/
@function getLegalEntityLogoWidth($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $width_logo_dts;
  } @else if($legal_entity == 'nfon') {
    @return $width_logo;
  } @else if($legal_entity == 'tef') {
    @return 3.5rem;
  }
}

@function getLegalEntityLogoHeight($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $height_logo_dts;
  } @else {
    @return 100%;
  }
}

@function getLegalEntityLogoPadding($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $padding_logo_dts;
  } @else {
    @return null;
  }
}

@function getLegalEntityLogoBGColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_white;
  } @else {
    @return null;
  }
}

/*########### Navigation Styling ###########*/

@function getLegalEntityNavigationFontWeight($legal_entity) {
  @if $legal_entity == 'dts' {
    @return null;
  } @else {
    @return 400;
  }
}

@function getLegalEntityNavigationSeperatorStyling($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_separator;
  } @else {
    @return $color_input_border_nfon;
  }
}

@function getLegalEntityNavigationCrossIconColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_white;
  } @else {
    @return $color_black;
  }
}

@function getLegalEntityNavigationCrossIconBgColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_component_background_dts;
  } @else {
    @return $color_secondary_nfon;
  }
}

@function getLegalEntityNavigationQuickSearchBgColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_white;
  } @else {
    @return $color_bg_grey_nfon;
  }
}

@function getLegalEntityPrimaryBgColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_component_background_dts;
  } @else {
    @return $color_primary_nfon;
  }
}

@function getLegalEntityInvertedLinkButton($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_component_background_dts;
  } @else {
    @return $color_secondary_nfon;
  }
}

@function getLegalEntitySelectBGColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_white;
  } @else {
    @return $color_input_bg_nfon;
  }
}

@function getLegalEntitySecondaryBgColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_component_background_dts;
  } @else {
    @return $color_secondary_nfon;
  }
}

@function getLegalEntityAuthBoxBackgroundColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_white;
  } @else {
    @return $color_bg_grey_nfon;
  }
}

@function getLegalEntityButtonColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_white;
  } @else {
    @return $color_black;
  }
}

@function getLegalEntityPasswordStrengthBgColor($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $color_passwordStrengthIndicator;
  } @else {
    @return $color_black;
  }

}

@function getLegalEntityHeaderLogo($legal_entity) {
  @if $legal_entity == 'dts' {
    @return null;
  } @else {
    @return auto;
  }
}

@function getLegalEntityAuthImage($legal_entity) {
  @if $legal_entity == 'dts' {
    @return url(../../assets/images/setting-up-is-this-easy.svg);
  } @else {
    @return url(../../assets/images/login-image-nfon.png);
  }
}

@function getLegalEntityButtonTransform($legal_entity) {
  @if $legal_entity == 'dts' {
    @return uppercase;
  } @else {
    @return none;
  }
}

@function getLegalEntityBodyFontSize($legal_entity) {
  @if $legal_entity == 'dts' {
    @return $fontsize_medium;
  } @else {
    @return $fontsize_medium;
  }
}

@mixin legalEntityInputBorderErrorStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      @if ($le) == 'dts' {
        border: 1px solid $color_error !important;
      } @else {
        border: .5px solid $color_error_nfon !important;
        background-color: $color_bg_grey_nfon !important;
      }
    }
  }
}

@mixin legalEntityInlineErrorStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      font-size: $fontsize_tiny;
      @if ($le) == 'dts' {
        color: $color_error;
        font-weight: 500;
      } @else {
        color: $color_error_nfon;
        font-weight: 400;
      }
    }
  }
}

@mixin legalEntityInlineSuccessStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      @if ($le) == 'dts' {
        color: $color_success;
      } @else {
        color: $color_secondary_nfon;
      }
    }
  }
}

@mixin legalEntityErrorMessage() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: var(--text-color);
      background-color: getLegalEntityPrimaryContrastColor($le);
    }
  }
}

@mixin legalEntityUserInfoComponentPrimaryStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: var(--text-color);
      background-color: getLegalEntityPrimaryContrastColor($le);
    }
  }
}

@mixin legalEntityErrorMessageStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      @if $le == 'dts' {
        margin-top: 2rem;
        color: $color_error;
      } @else {
        display: flex;
        color: $color_white;
        background: $color_error_nfon;
        margin-top: 32px;
        margin-bottom: 32px;
        padding: 12px 16px;
        gap: 16px;
      }
    }
  }
}

@mixin legalEntityFooterStyle() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityFooterColor($le);

      a {
        @if $le == "dts" {
          color: var(--primary-color) !important;
          font-weight: 500;
        } @else {
          color: getLegalEntitySwitchOffColor($le) !important;
        }
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@mixin legalEntityAvatarStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityPrimaryFontColor($le);
      background: getLegalEntityAvatarBackground($le);
      border: 1px solid #FFFFFF;
    }
  }
}

@mixin legalEntityPageStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: var(--bg-color);
      color: var(--text-color);
      font: var(--typo-body);
    }
  }
}

@mixin legalEntityHeaderStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: var(--text-color);
      background-color: var(--bg-contrast-color);
      height: $height_headerTop;

      @if $le == 'dts' {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

        .container {
          padding-top: $height_logoGap;
        }
      } @else {
        .container {
          padding: 1.5rem 0;
          align-items: center;
        }
      }
    }
  }
}

@mixin legalEntityLogoStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      width: getLegalEntityLogoWidth($le);
      height: getLegalEntityLogoHeight($le);
      padding: getLegalEntityLogoPadding($le);
      background: getLegalEntityLogoBGColor($le);
      @if $le == 'dts' {
        box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.16);
      } @else {
        display: flex;
      }
    }
  }
}

@mixin legalEntityHeaderLogo() {
  @each $le in $legal_entities {
    &--#{$le} {
      margin-left: getLegalEntityHeaderLogo($le);
      @if $le != 'dts' {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}

@mixin legalEntityPaginationColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityPaginationColor($le);
      font-weight: 500;
    }
  }
}

@mixin legalEntityPaginationActiveColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityPaginationActiveColor($le);
    }
  }
}

@mixin legalEntityPaginationSizeSelect() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityPaginationSizeSelectColor($le);
    }
  }
}

@mixin legalEntityCustomerBoxLineStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityCustomerBoxFontColor($le);
      border-bottom: 1px solid getLegalEntityMonthlyTableLines($le);
    }
  }
}

@mixin legalEntityMenuLineStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      border-bottom: $dimension_boxBorder solid getLegalEntityMonthlyTableLines($le);
    }
  }
}

@mixin legalEntityCustomerBoxStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityCustomerBoxFontColor($le);
    }
  }
}

@mixin legalEntityStatusColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      svg {
        g {
          fill: getLegalEntityStatusColor($le);
        }
      }
    }
  }
}

@mixin legalEntityPaginationSelectColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityPaginationSelectColor($le);
      border: solid 1px getLegalEntityModalBoxInputBorderColor($le);
      background: transparent;

      @if $le != 'dts' {
        option {
          background: $color_bg_grey_nfon;
        }
      }
    }
  }
}

@mixin legalEntityActiveFontColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityActiveFontColor($le);

      path {
        fill: getLegalEntityIconColor($le);
      }
    }
  }
}

@mixin legalEntityActiveBackgroundColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      background-color: getLegalEntityActiveBackgroundColor($le);
      @if ($le) == 'dts' {
        font-weight: 500 !important;
      }
    }
  }
}

@mixin legalEntityTableFirstChildFontColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityActiveFontColor($le);
      @if ($le) != 'dts' {
        button,
        a {
          &:hover {
            text-decoration: underline;
            color: var(--primary-color);
            cursor: pointer;
          }
        }

        a:active {
          color: getLegalEntityActiveButtonColor($le) !important;
        }
      }
    }
  }
}

@mixin legalEntitySubLabelColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityHelpBoxColor($le);
    }
  }
}

@mixin legalEntityBodyFontSize() {
  @each $le in $legal_entities {
    &--#{$le} {
      font-size: getLegalEntityBodyFontSize($le) !important;
    }
  }
}

@mixin legalEntitySmallFontSize() {
  @each $le in $legal_entities {
    &--#{$le} {
      font-size: $fontsize_small !important;
    }
  }
}

@mixin legalEntityChargesSumStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      td {
        border-top: 1px solid getLegalEntityMonthlyTableLines($le);
      }

      td:last-child {
        font-weight: 500;
      }
    }
  }
}

@mixin legalEntityTableLinesStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      td {
        border-top: 1px solid getLegalEntityTableLines($le);
        vertical-align: top;
      }
    }
  }
}

@mixin legalEntityLastTableLinesStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      border-bottom: 1px solid getLegalEntityTableLines($le);
    }
  }
}

@mixin legalEntityFirstTableLinesStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      td {
        border-top: 1px solid getLegalEntityTableFirstLine($le);
      }
    }
  }
}

@mixin legalEntityInputLabelFontStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityDarkColor($le);
    }
  }
}

@mixin legalEntityInputStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      border-radius: var(--input-border-radius);
      border: var(--input-border);
      background-color: var(--input-bg-color);
      color: var(--input-color);

      &:disabled {
       background-color: getLegalEntityDisabledBackgroundColor($le);
       opacity: 0.8;
      }

      &::placeholder {
        color: getLegalEntityInputPlaceholderColor($le);
      }

      &:focus-visible {
        outline: getLegalEntityInputFocus($le);
      }

      @if ($le) == 'dts' {
        font-size: $fontsize_medium;
        &::placeholder {
          color: $color_lightBlueGrey;
        }
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        @if ($le) == 'dts' {
          -webkit-box-shadow: 0 0 0 30px $color_white inset !important;
          -webkit-text-fill-color: $color_primary_dts !important;
        } @else {
          -webkit-box-shadow: 0 0 0 30px $color_input_bg_nfon inset !important;
          -webkit-text-fill-color: $color_white !important;
        }
      }
    }
  }
}

@mixin legalEntityCustomerDataHeadingStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getDTSPrimaryColor($le);
    }
  }
}

@mixin legalEntityCustomerDataOverviewFontStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityPrimaryFontColor($le);
    }
  }
}

@mixin legalEntityComponentPrimaryStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      @if $le == 'dts' {
        background: var(--primary-color);
      } @else {
        background: var(--highlight-color);
      }
      color: $color_white;
    }
  }
}

@mixin legalEntityComponentInvertedPrimaryStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: getLegalEntityAuthBoxBackgroundColor($le);
      color: getLegalEntityContextColor($le);
      border: getLegalEntityBoxBorder($le);
    }
  }
}

@mixin legalEntityComponentInvertedLinkStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: var(--primary-color);
      font: var(--typo-button);

      @if $le == 'dts' {
        font-weight: 700 !important;
        color: var(--primary-color) !important;
      }

      &.white-override {
        @if $le == 'dts' {
          color: var(--primary-color) !important;
          font-weight: 700 !important;
        }
      }

      &:hover {
        text-decoration: underline;
        color: $color_button_hover_nfon !important;
      }

      &.white-override {
        &:hover {
          color: $color_white !important;
        }
      }

      &:active {
        color: getLegalEntityActiveButtonColor($le) !important;
      }

      &::after {
        content: '›';
        padding-left: $width_spaceBeforeLinkMarker;
        font-size: $fontsize_body;
        display: inline-block;
        text-decoration: none;
      }
    }
  }
}

@mixin legalEntityComponentInvertedLinkWithIconStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityInvertedLinkButton($le);
      font: var(--typo-button);


      @if $le == 'dts' {
        color: $color_component_background_dts !important;
      } @else {
        &:hover {
          text-decoration: underline;
          color: $color_button_hover_nfon !important;
        }

        &:active {
          color: getLegalEntityActiveButtonColor($le) !important;
        }
      }
    }
  }
}

@mixin legalEntitySelectStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background-color: getLegalEntitySelectBGColor($le);
    }
  }
}

@mixin legalEntityButtonStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: var(--button-bg-color);
      color: var(--button-text-color);
      text-transform: var(--button-text-transform);
      border-radius: var(--button-border-radius);
      font: var(--typo-button);

      @if $le == 'dts' {
        font-weight: 500;
      }
    }

    &--#{$le}:hover:not(:disabled) {
      background: var(--button-bg-hover-color);
    }

    &--#{$le}:disabled,
    &--#{$le}:disabled:active {
      cursor: not-allowed;
      background: var(--button-disabled-bg-color);
    }

    &--#{$le}:active {
      background: var(--button-active-bg-color);
    }
  }
}

@mixin legalEntityButtonSecondaryStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: var(--button-bg-color);
    }
    &--#{$le}:hover:not(:disabled) {
      color: var(--button-bg-hover-color);
    }
    &--#{$le}:disabled,
    &--#{$le}:disabled:active {
      cursor: not-allowed;
      color: var(--button-disabled-bg-color);
    }
    &--#{$le}:active {
      background: none;
    }
  }
}

@mixin legalEntityButtonStylingNoBG() {
  @each $le in $legal_entities {
    &--#{$le} {
      background-color: getLegalEntityEditButtonBGColor($le);
      color: getLegalEntityEditButtonColor($le);
      font-weight: getLegalEntityMoreLinkFont($le);
    }
    &--#{$le}:hover:not(:disabled) {
      background-color: getLegalEntityEditButtonBGColor($le);
    }
  }
}

@mixin legalEntityComponentSecondaryStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: var(--highlight-color);
      color: var(--text-color);
    }
  }
}

@mixin legalEntityComponentStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: var(--card-color);
      color: var(--text-color);
      border: 1px solid var(--card-border-color);
    }
  }
}

@mixin legalEntityComponentDTSShadow() {
  @each $le in $legal_entities {
    &--#{$le} {
      @if $le == 'dts' {
        box-shadow: 0 2px 8px 0 rgba(195, 195, 195, 0.5);
      }
    }
  }
}

@mixin legalEntityProgressbarStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: getLegalEntityProgressbarBackgroundColor($le);

      .progressBarInner {
        background: getLegalEntityProgressbarColor($le);
      }

      .progressBarInner-le--dts {
        background: $color_darkGrey !important;
      }

      .securityProgressBarInner {
        background: getLegalEntityProgressbarColor($le);
      }
    }
  }
}

@mixin legalEntityProgressbarLegendUsedStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      @if($le) == 'dts' {
        background: $color_darkGrey !important;
      } @else {
        background-color: getLegalEntityProgressbarColor($le);
      }
    }
  }
}

@mixin legalEntityProgressbarLegendFreeStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background-color: getLegalEntityProgressbarBackgroundColor($le);
    }
  }
}

@mixin legalEntityIconStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      path {
        &:active {
          fill: getLegalEntityIconColor($le);
        }
      }
    }
  }
}

@mixin legalEntitySecondaryIconStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      path {
        @if $le == 'dts' {
          fill: getLegalEntitySecondaryIconColor($le) !important;
        } @else {
          fill: $color_white;
        }

        &:active {
          fill: getLegalEntitySecondaryIconColor($le) !important;
        }
      }
    }
  }
}

@mixin legalEntityModalTitle() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityModalTitleColor($le);
    }
  }
}

@mixin legalEntityModalSummaryTable() {
  @each $le in $legal_entities {
    &--#{$le} {
      td {
        color: getLegalEntityModalTitleColor($le);
      }
    }
  }
}

@mixin legalEntityModalOstSummaryTable() {
  @each $le in $legal_entities {
    &--#{$le} {
      &:last-child {
        font-weight: 500;
      }
    }
  }
}

@mixin legalEntityIconPathStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      svg {
        path {
          fill: getLegalEntityModalSummaryInfoFontIconColor($le);
        }
      }
    }
  }
}

@mixin legalEntityStatusIconPathStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      svg {
        path {
          fill: var(--primary-color);
        }
      }
    }
  }
}

@mixin legalEntityDisabledSelectBoxBackground() {
  @each $le in $legal_entities {
    &--#{$le} {
      @if ($le) != 'dts' {
        background: rgba(48, 48, 48, 0.5);
      } @else {
        background: rgba(100, 100, 100, 0.2);
      }
    }
  }
}

@mixin legalEntityTitleColorStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      h2 {
        color: getLegalEntityModalSummaryInfoFontIconColor($le);
      }
    }
  }
}

@mixin legalEntitySubTitleFontStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      //font: var(--typo-body);
      @if ($le) != 'dts' {
        margin-top: $height_mediumSmallSpace;
      }
    }
  }
}

@mixin legalEntityModalProgressbarStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background-color: getLegalEntityModalPrimaryColor($le);
    }
  }
}

@mixin legalEntityModalBoxes() {
  @each $le in $legal_entities {
    &--#{$le}:not(.disabled) {
      &:focus,
      &:focus-within {
        background-color: getLegalEntitySelectedBoxColor($le);
        border-color: getLegalEntitySelectedBoxBorderColor($le);

        h4,
        p,
        span,
        button {
          color: getLegalEntityModalBoxColor($le);
          pointer-events: none;
        }

        g {
          fill: getLegalEntityModalBoxIconColor($le);
        }
      }
    }
  }
}

@mixin legalEntityModalBoxInput() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityModalBoxInput($le);
      border: solid 1px getLegalEntityModalBoxInputBorderColor($le);
      @if $le != 'dts' {
        background: $color_input_bg_nfon;

        &::placeholder {
          color: $color_switchOff_nfon;
        }
      }
    }
  }
}

@mixin legalEntitySelectBoxActive() {
  @each $le in $legal_entities {
    &--#{$le} {
      background-color: getLegalEntitySelectedBoxColor($le);
      border: 2px solid getLegalEntitySelectedBoxBorderColor($le);

      h4,
      p,
      span {
        color: getLegalEntityModalBoxColor($le);
        pointer-events: none;
      }

      p.selectText {
        color: getLegalEntitySelectedTextColor($le);
      }

      g {
        fill: getLegalEntityModalBoxIconColor($le);
      }

      .innerBox {
        .current {
          svg {
            path {
              fill: getLegalEntityModalBoxIconColor($le);
            }
          }
        }
      }
    }
  }
}

@mixin legalEntityEditBoxActive() {
  @each $le in $legal_entities {
    &--#{$le} {
      background-color: getLegalEntityBrandColor($le);
     // border: 2px solid getLegalEntitySelectedBoxBorderColor($le);

      h4,
      p,
      span,
      button {
        color: getLegalEntityModalBoxColor($le);
        pointer-events: none;
      }

      p.selectText {
        color: getLegalEntitySelectedTextColor($le);
      }

      g {
        fill: getLegalEntityModalBoxIconColor($le);
      }

      .innerBox {
        .current {
          svg {
            path {
              fill: getLegalEntityModalBoxIconColor($le);
            }
          }
        }
      }
    }
  }
}

@mixin legalEntityNavFontColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityNavFontColor($le);
    }
  }
}

@mixin legalEntityPrimaryFontColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: var(--primary-color);
    }
  }
}

@mixin legalEntitySearchBoxStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      @if ($le) == 'dts' {
        font-weight: 500;
      } @else {
        font-weight: 700;
      }
    }
  }
}

@mixin legalEntityFigureBoxStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: getLegalEntityFigureBoxBackgroundColor($le);
      color: getLegalEntityFigureBoxColor($le);
    }
  }
}

@mixin legalEntityFigureBoxStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: getLegalEntityFigureBoxBackgroundColor($le);
      color: getLegalEntityPrimaryFontColor($le);
      border: 1px solid getLegalEntityCompBorderColor($le);
    }
  }
}

@mixin legalEntityIconsPrimaryStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      g {
        fill: var(--primary-color);
      }
    }
  }
}

@mixin legalEntityIconsSecondaryStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      g {
        fill: getLegalEntityActiveFontColor($le);
      }
    }
  }
}

@mixin legalEntityIconsPrimaryFontColorStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      g {
        fill: getLegalEntityPrimaryFontColor($le);
      }
    }
  }
}

@mixin legalEntityHelpBoxErrorIconStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      g {
        fill: getLegalEntityErrorColor($le);
      }
    }
  }
}

@mixin legalEntityHelpBoxIconStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      g {
        fill: getLegalEntityHelpBoxIconColor($le);
      }

      @if $le == 'dts' {
        :hover {
          g {
            fill: getLegalEntityHelpBoxIconHoverColor($le);
          }
        }

        :focus {
          g {
            fill: getLegalEntityHelpBoxIconHoverColor($le);
          }
        }
      }
    }
  }
}

@mixin legalEntityHelpBoxStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityHelpBoxColor($le);
      background: getLegalEntityFigureBoxBackgroundColor($le);
      font: var(--typo-body-small);
    }
  }
}

@mixin legalEntityIconsDarkStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      g {
        fill: getLegalEntityDarkColor($le);
        opacity: getLegalEntityDarkIconOpacity($le);
      }
    }
  }
}

@mixin legalEntityPrimaryLinkColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityPrimaryLinkColor($le);
      font-weight: getLegalEntityMoreLinkFont($le);
    }
  }
}

@mixin legalEntityPrimaryLinkStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      &,
      &:link,
      &:hover,
      &:visited {
        color: getLegalEntityPrimaryLinkColor($le);
        font-weight: getLegalEntityMoreLinkFont($le);
      }
    }
  }
}

@mixin legalEntityContrastLinkColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityContrastLinkColor($le);
    }
  }
}

@mixin legalEntityPageTitleStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityPageTitleColor($le);
      margin-top: -1rem;
    }
  }
}

@mixin legalEntityPageSubTitleStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityPageTitleColor($le);
      @if $le == 'dts' {
        font-size: $fontsize_h4;
      } @else {
        font-size: $fontsize_medium;
      }
    }
  }
}

@mixin legalEntityPageContextStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityContextColor($le);

      @if $le != 'dts' {
        font-size: $fontsize_medium;
      }
    }
  }
}

@mixin legalEntityPageSubtitleStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityPageSubtitleColor($le);
    }
  }
}

@mixin legalEntityLoginLabelStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: var(--text-color);
    }
  }
}

@mixin legalEntityBoxHeaderStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      th {
        color: getLegalEntityProgressbarFontColor($le);
        border-bottom: 1px solid getLegalEntityTableFirstLine($le);
      }
    }
  }
}

@mixin legalEntityTableFirstChildStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: var(--primary-color);
      @if($le) == 'dts' {
        p {
          font-weight: 500;
        }
      } @else {
        p {
          font-weight: 700;
        }

        .desc {
          font-weight: 400 !important;
          font-size: 14px;
        }
      }
    }
  }
}

@mixin legalEntityUserIconStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityUserIconColor($le);;
    }
  }
}

@mixin legalEntityComponentBoxHeaderIconStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      svg {
        path {
          fill: getLegalEntityIconColor($le);
        }

        g {
          g {
            fill: getLegalEntityIconColor($le);
          }
        }
      }
    }
  }
}

@mixin legalEntityLoginInputStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityDarkColor($le);
    }
  }
}

@mixin legalEntityCustomerDataLabelStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: var(--text-color);
      margin-bottom: .5rem;
    }
  }
}

@mixin legalEntityCustomerContractPersonStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityContractPersonColor($le);
      @if($le) == 'dts' {
        font-weight: 500;
      } @else {
        font-weight: 700;
      }

      svg {
        g {
          fill: getLegalEntityStatusColor($le);
        }
      }
    }
  }
}

@mixin legalEntityCustomerContractPersonTextStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      p {
        color: getLegalEntityContextColor($le);
      }
    }
  }
}

@mixin legalEntitySwitchStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      &::before {
        border: 1px solid getLegalEntitySwitchOffColor($le) !important;
      }

      &::after {
        background: getLegalEntitySwitchOffColor($le);
      }

      &:hover {
        &::before {
          border-color: getLegalEntitySwitchOffColor($le) !important;
        }

        &::after {
          background: getLegalEntitySwitchOffColor($le);
        }
      }

      &.on {
        &::before {
          @if $le == 'dts' {
            border: solid 1px getLegalEntityPrimarySwitchColor($le);
          }
          opacity: 0.4 !important;
          background: getLegalEntitySwitchBGColor($le);
          border-color: getLegalEntityPrimarySwitchColor($le) !important;
        }

        &::after {
          background: var(--primary-color);
        }

        &:hover {
          &::before {
            border-color: getLegalEntityPrimaryHoverColor($le);
            @if $le == 'dts' {
              border: solid 2px $color_switchHover_outerHover_dts;
            }
          }

          &::after {
            //background: getLegalEntityPrimaryHoverColor($le);
          }
        }
      }
    }
  }
}

@mixin legalEntitySwitchWithTextStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      &::before {
        @if $le == 'dts' {
          background: $color_white;
          border: 1px solid var(--primary-color);
        } @else {
          background: #303030;
          border: 1px solid #666666 !important;

        }
      }

      &::after {
        background: var(--primary-color);
      }

      &.on {
        &::before {
          @if $le == 'dts' {
            border: 1px getLegalEntityPrimarySwitchColor($le);
          }
          background: #303030;
          border: getLegalEntityBoxBorder($le);
        }

        &::after {
          background: var(--primary-color);
        }
      }
    }
  }
}

@mixin legalEntitySwitchWithTextParagraphStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      font-size: 14px;
      position: absolute;
      top: 50%;
      transform: translateY(-45%);
      z-index: 2;
      user-select: none;

      @if $le == 'dts'{
        color: var(--primary-color);
      } @else {
        color: white;
      }

      &.disabled {
        opacity: .3 !important;
        cursor: not-allowed;
      }

      &.active {
        @if $le == 'dts'{
          color: white;
        } @else {
          color: black;
        }
        transition: color .3s ease-in-out;
      }
    }
  }
}

@mixin legalEntityDateInputIconStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      padding-left: 10px;

      @if $le == 'dts' {
        background: $color_white;
        color: var(--primary-color);
      } @else {
        background: #4A4A4A;
      }
    }
  }
}

@mixin legalEntityTableHeaderStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: var(--text-color);
      font: var(--typo-body-small);
      @if ($le) == 'dts' {
        font-weight: 700;
      } @else {
        font-weight: 300;
      }

      th {
        border-bottom: 1px solid getLegalEntityTableFirstLine($le);
      }
    }
  }
}

@mixin LegalEntityTableHeaderFontFamily() {
  @each $le in $legal_entities {
    &--#{$le} {
      font-weight: getLegalEntityTableHeaderFontFamily($le);
      @if ($le) != 'dts' {
        font-size: $fontsize_nfon_h2 !important;
      }
    }
  }
}

@mixin legalEntityPrimaryPathStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      path {
        fill: var(--primary-color);
      }
    }
  }
}

@mixin legalEntityDarkPathStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      path {
        fill: getLegalEntityDarkColor($le);
      }
    }
  }
}

@mixin bottomLinkStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      font-weight: getLegalEntityMoreLinkFont($le);
      color: bottomLinkColor($le);
    }
  }
}

@mixin bottomLinkCustomerContractStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      font-weight: getLegalEntityMoreLinkFont($le);
      color: var(--primary-color);
      line-height: 26px;
      @if ($le) == 'dts' {
        font-size: $fontsize_body;
      } @else {
        font-size: $fontsize_nfon_small;
      }
    }
  }
}

@mixin labelInfrastructureBoxStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityModalSummaryInfoFontIconColor($le);

      g {
        fill: getLegalEntityModalSummaryInfoFontIconColor($le);
      }
    }
  }
}

@mixin textDetailBoxStyling() {
  @each $le in $legal_entities {
    &--#{$le} {

      @if $le == "dts" {
        .label {
          color: var(--primary-color);
        }
      }
    }
  }
}

@mixin packageRowStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      .package-row__name {
        color: getLegalEntityLoginLabelColor($le);
      }

      .package-row__size {
        font-weight: 500;
        color: getLegalEntityLoginLabelColor($le);
      }
    }
  }
}

@mixin legalEntitySelectBoxSelectText() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityPrimaryLinkColor($le);
      font-weight: getLegalEntityMoreLinkFont($le);
      text-transform: getLegalEntityButtonTransform($le);
    }
  }
}

@mixin legalEntityLineColorDark() {
  @each $le in $legal_entities {
    &--#{$le} {
      border: 1px solid getLegalEntityLineColorDark($le);
    }
  }
}

@mixin legalEntityQuickSearchButton() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityPageSubtitleColor($le);
      font-weight: 400 !important;

      &:hover,
      &:focus {
        @if $le == 'dts' {
          color: getLegalEntityButtonSecondaryColor($le);
        } @else {
          background: rgba(150, 150, 150, 0.15); // TODO var
        }
      }
    }
  }
}

@mixin legalEntityNavigationStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: var(--bg-contrast-color);
      color: var(--subtitle-color);
      font: var(--typo-body);

      .nav-link-parent {
        &:hover {
          color: var(--primary-color) !important;
          background: var(--hover-color) !important;
        }
      }

      @if $le == 'dts' {
        @include hasBoxShadow;
      } @else {
        .nav-link-parent {
          font: var(--typo-h5);
        }
      }
    }
  }
}

@mixin legalEntityBoxShadow() {
  @each $le in $legal_entities {
    &--#{$le} {
      @if $le == 'dts' {
        @include hasBoxShadow;
      } @else {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5) !important;
      }
    }
  }
}

@mixin legalEntityNavigationSeperatorStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      border-bottom: 1px solid getLegalEntityNavigationSeperatorStyling($le);
    }
  }
}

@mixin legalEntityNavigationFontWeight() {
  @each $le in $legal_entities {
    &--#{$le} {
      font-weight: getLegalEntityNavigationFontWeight($le);
      @if ($le) != 'dts' {
        padding-bottom: $height_paddingNav;
      }
    }
  }
}

@mixin legalEntityNavigationCrossIconStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: getLegalEntityNavigationCrossIconBgColor($le);

      path {
        fill: getLegalEntityNavigationCrossIconColor($le) !important;
      }
    }
  }
}

@mixin legalEntityNavigationQuickSearchBgColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: getLegalEntityNavigationQuickSearchBgColor($le);
    }
  }
}

@mixin legalEntityPasswordStrengthBgColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: getLegalEntityPasswordStrengthBgColor($le);
    }
  }
}

@mixin legalEntityUserNavigationStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: var(--card-color);

      h3 {
        color: getLegalEntityContrastLinkColor($le);
      }

      p {
        color: getLegalEntityUserNavigationPrefUserColor($le);
        @if $le == 'dts' {
          font-weight: 700;
        }
      }

      a,
      button {
        color: getLegalEntityPageSubtitleColor($le);

        &:hover {
          @if $le == 'dts' {
            color: $color_white !important;
            background: $color_button_secondary_dts !important;
          } @else {
            background: rgba(150, 150, 150, 0.15) !important; // TODO var
          }
        }
      }
    }
  }
}

@mixin legalEntityUserNavigationPrefUserColor() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityUserNavigationPrefUserColor($le);
    }
  }
}

@mixin legalEntityAuthImage() {
  @each $le in $legal_entities {

    &--#{$le} {
      &::before {
        background-image: getLegalEntityAuthImage($le);
        @if $le == 'dts' {
          left: $width_loginImageMargin_dts !important;
          width: $width_gridCol3 + $width_gridGap - ($width_loginImageMargin_dts * 2) !important;
        } @else {
          background-size: auto !important;
          background-repeat: no-repeat !important;
          background-position: center top !important;
        }
      }
    }
  }
}

@mixin legalEntityLangSelectorStyling() {
  @each $le in $legal_entities {
    &--#{$le} {

      @if $le != 'dts' {
        h5 {
          margin-top: .25rem;

          &.isExpanded {
            color: $color_secondary_nfon;
          }
        }

        &.isExpanded {
          svg {
            fill: $color_secondary_nfon;
          }
        }
      }
    }
  }
}

@mixin legalEntityHeadingStylingH4() {
  @each $le in $legal_entities {
    &--#{$le} {
      color: getLegalEntityContrastLinkColor($le);
    }
  }
}

@mixin legalEntityConfirmationModalStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      @if $le != 'dts' {
        font-weight: getLegalEntityTableHeaderFontFamily($le);
      }
    }
  }
}

@mixin legalEntityWarningBoxStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background-color: $color-warning;
      color: getLegalEntityPrimaryContrastColor($le);

      svg {
        path {
          fill: getLegalEntityPrimaryContrastColor($le);
        }
      }
    }
  }
}

@mixin legalEntityInactiveStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      td:last-child {
        @if($le) == 'dts' {
          color: $color_fontInactive;
        } @else {
          color: $color_switchOff_nfon;
        }
      }
    }
  }
}

@mixin legalEntityToastErrorStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      padding: $dimension_toastIconSpace;
      padding-left: 2 * $dimension_toastIconSpace + $dimension_toastIcon;
      color: $color_white;
      cursor: pointer;

      h4 {
        margin-bottom: $height_miniSpace;
      }
      a,
      a:link,
      a:hover,
      a:visited {
        color: $color_white;
      }
      svg {
        width: $dimension_toastIcon;
      }
      @if($le) == 'dts' {
        background: $color_error;
        border-radius: $dimension_toastBorderRadius;
        font-weight: 300;
        box-shadow: 0.625rem 0.625rem 2.75rem 0 $color_toastBoxShadow;
      } @else {
        background: $color_error_nfon;
      }
    }
  }
}

@mixin legalEntityStepperFooterStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: getLegalEntityStepperFooterBackgroundColor($le);
      color: getLegalEntityPrimaryFontColor($le);
      border-top: 1px solid getLegalEntityCompBorderColor($le);
    }
  }
}

@mixin legalEntityWhiteButtonStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      background: $color_white;
      color: getLegalEntityStepperFooterBackgroundColor($le);
      border: 1px solid $color_white;

      @if($le) == 'dts' {
        &:hover {
          background: transparent;
          color: $color_white;
        }
      } @else {
        border-top: 1px solid $color_box_border_nfon;
      }
    }
  }
}

@mixin legalEntityStepperStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      .stepper-item {
        display: flex;
        align-items: center;
        color: var(--neutral-color);
        font-weight: 400;

        .circle {
          box-sizing: border-box;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          margin-right: 16px;
          border: 1px solid getLegalEntityDisabledColor($le);
          @if($le) == 'dts' {
            background: white;
          } @else {
            background: black;
          }
          position: relative;

          &:before {
            position: absolute;
            content: "";
            background: getLegalEntityDisabledColor($le);
            height: 130%;
            width: 1px;
            top: 100%;
            left: 48%;
          }
        }

        .line {
          box-sizing: border-box;
          width: 13px;
          height: 13px;
          margin-right: 32px;

          position: relative;

          &:before {
            position: absolute;
            content: "";
            background: getLegalEntityPrimaryLinkColor($le);
            height: 180%;
            width: 1px;
            top: 0;
            left: 49%;
          }
        }

        &:last-child {
          .circle:before {
            display: none;
          }
        }
      }

      .stepper-current {
        @if($le) == 'dts' {
          font-weight: 500;
        } @else {
          font-weight: 700;
        }
        color: getLegalEntityPrimaryLinkColor($le);

        .circle {
          border: 1px solid getLegalEntityPrimaryLinkColor($le);
          background: getLegalEntityPrimaryLinkColor($le);

          &:before {
            background: getLegalEntityPrimaryLinkColor($le);
          }
        }

        .line {
          &:before {
            background: getLegalEntityPrimaryLinkColor($le);
          }
        }
      }

      .stepper-item-active {
        color: getLegalEntityInputColorColor($le);

        .circle {
          border: 1px solid getLegalEntityInputColorColor($le);
          background: getLegalEntityInputColorColor($le);

          &:before {
            background: getLegalEntityInputColorColor($le);
          }
        }

        .line {
          &:before {
            background: getLegalEntityPrimaryLinkColor($le);
          }
        }
      }
    }
  }
}

@mixin legalEntityOrderOverviewBasketStyling() {
  @each $le in $legal_entities {
    &--#{$le} {

      td.product-name {
        @if $le == 'dts' {
          font-weight: 400;
        } @else {
          font-weight: 500 !important;
        }
      }

      .sum .line {
        border-bottom: none;
        border-top: 1px solid getLegalEntityMonthlyTableLines($le);
        @if $le == 'dts' {
          font-weight: 400;
        } @else {
          font-weight: 500 !important;
        }
      }

    }
  }
}

@mixin legalEntityIconBackgroundStyling() {
  @each $le in $legal_entities {
    &--#{$le} {
      @if $le == 'dts' {
        background: $color_lightGrey;
      } @else {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}