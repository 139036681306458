@import '../../../../../sass/base/common';
@import '../../../../../sass/base/legal_entities';

.preferredContact {
  @include box;
  background: $color_white;

  &-le {
    @include legalEntityComponentStyling();
    @include legalEntityBodyFontSize();
  }

  .preferredContactBox {
    // margin-bottom: $height_mediumSpace;
    display: flex;
    justify-content: space-between;

    section {
      width: 269px;
      //   margin-right: 261px;
    }

    &-le {
      @include legalEntityCustomerContractPersonTextStyling();
    }
  }

  h3,
  h4 {
    display: flex;
    align-items: center;
    margin-bottom: $height_mediumSpace;

    &.componentTitle {
      margin-bottom: 2rem !important;
      &-le {
        @include legalEntityCustomerDataLabelStyling();
        @include legalEntityHeadingStylingH4();
      }
    }
  }

  a {
    text-decoration: none;
  }

  .hidden {
    visibility: hidden;
  }
}
