@import '../../../../sass/base/legal_entities';
@import '../../../../sass/base/typography';

.customerOverview {

  .rightBoxs {
    margin-left: 1rem;

    & > div:first-child {
      margin-bottom: 1rem;
    }
  }
}
