@import '../../portal/sass/base/dimensions';
@import '../../portal/sass/base/legal_entities';
@import '../../portal/sass/base/colors';

.paginationSizeSelect {
  position: absolute;
  right: 0;
  top: -0.3125rem;
  font-size: 14px;

  &-le {
    @include legalEntityPaginationSizeSelect();
  }

  .select {
    height: $height_select;
    padding: 0 $width_selectPadding;
    cursor: pointer;
    margin-right: $width_paginationInfoSpace;
    font-size: 16px;
    border-radius: 2px;
    background-color: $color_white;

    &-le {
      @include legalEntityPaginationSelectColor();
    }
  }
  label {
    margin-right: $width_paginationLabelSpace;
  }
}
