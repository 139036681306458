@import 'src/portal/sass/base/legal_entities';

.quoteItemsTable {

  &-charge-edit{
    display: flex;
    gap: 1rem;
    align-items: center;
    svg {
      g, path {
        fill: var(--primary-color);
      }
    }
  }
  .hierarchy-level-0 {
    padding-left: 0;
  }
  .hierarchy-level-1 {
    padding-left: 1rem;
  }
  .hierarchy-level-2 {
    padding-left: 2rem;
  }
  .hierarchy-level-3 {
    padding-left: 3rem;
  }
  .hierarchy-level-4 {
    padding-left: 4rem;
  }
  .hierarchy-level-5 {
    padding-left: 5rem;
  }

  td, th {
    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 10%;
      text-align: center;
    }

    &:nth-child(3) {
      width: 20%;
      text-align: right;
    }
    &:nth-child(4) {
      width: 10%;
      text-align: right;
    }
    &:nth-child(5) {
      width: 20%;
      text-align: right;
    }
    &:nth-child(6) {
      width: 10%;
      text-align: right;
    }

  }

  tr > td.basket-address {
    font-size: $fontsize_small;
    font-weight: 100;
    padding-left: 3rem !important;
    border: none;
  }


}