@import "../../portal/sass/base/common";
@import "../../portal/sass/base/legal_entities";

.goBackLink {

  &-le {
    @include legalEntityStatusIconPathStyling;
    @include legalEntityComponentInvertedLinkWithIconStyling();
  }

  &,
  &:link,
  &:hover,
  &:visited {
    @include hasIcon;
    cursor: pointer;
    display: flex;
    align-items: center;

    & > svg:first-child {
      transform: scale(0.9);
    }
  }
}