@import '../../portal/sass/base/colors';
@import '../../portal/sass/base/common';
@import '../../portal/sass/base/dimensions';
@import '../../portal/sass/base/legal_entities';

.backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 200;
}

.modal {
  position: fixed;
  max-height: calc(100vh - 210px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 37rem;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 30px 24px 24px;
  box-shadow: 10px 20px 44px 0 rgba(0, 0, 0, 0.19);
  z-index: 201;

  &-large {
    width: 55rem;
  }

  &-dialog {
    background: var(--bg-contrast-color);
    color: var(--text-color);
    border: var(--input-border);
    &-le--dts {
      border: none;

      h3 {
        color: var(--primary-color);
        font-weight: 500;
      }
    }
  }

  .close {
    @include buttonInDisguise;
    position: absolute;
    margin-top: 20px;
    right: $dimension_modalPadding;
    top: $dimension_modalPadding;

    &-le {
      @include legalEntityIconsPrimaryStyling();
    }
  }

  .customButton {
    padding-left: 0;
    padding-top: 0;
  }

  #add-block-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;

    p {
      margin-top: .25rem;
    }
  }

  .twoButton {
    display: flex;
    margin-top: $dimension_modalPadding;
    justify-content: flex-end;

    button:last-child {
      margin-left: auto;
      margin-left: $dimension_boxSpace;
    }
  }

  .threeButton {
    display: flex;
    margin-top: $dimension_modalPadding;
    justify-content: space-between;

    button:first-child {
      margin-right: $dimension_boxSpace;
    }
  }

  .bottomText {
    font-size: 14px;
  }

  .modalTitle {
    margin-bottom: 1rem;
  }

  &.orderEntryTelephoneConnections {
    p {
      margin-top: .5rem;
    }
  }
}
