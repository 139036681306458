@import "../../portal/sass/base/colors";
@import "../../portal/sass/base/typography";
@import "../../portal/sass/base/dimensions";
@import 'src/portal/sass/base/legal_entities';

.dateInputWithLabel {
  margin-bottom: 1rem;
  position: relative;
  padding: 0;
  box-sizing: border-box;
  
  .dateInput-wrapper {
    position: relative;

    input[type="date"]::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    .dateInput-icon {

      &-le {
        @include legalEntityDateInputIconStyling();
      }
    }
  }

  label {
    line-height: 1.25rem;
    font-size: $fontsize_small;
    margin-bottom: 4px;
    display: block;
  }

  .label {
    &-le {
      @include legalEntityLoginLabelStyling();
    }
  }

  .required {
    &.label-helpBox {
      &::after {
        content: "" !important;
      }
    }

    .input-helpBox {
      &::after {
        content: " *";
        margin-left: -5px;
      }
    }

    &::after {
      content: " *";
    }
  }

  .input {
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    line-height: 1.4;
    padding: $height_inputPadding $width_inputPadding;
    font-family: inherit;
    font-weight: inherit;

    &-le {
      @include legalEntityInputStyling();
    }

    &:required ~ label::after {
      content: " *";
    }

  }
}
