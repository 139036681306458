@import 'src/portal/sass/base/legal_entities';
@import '../../../sass/base/colors';
@import '../../../sass/base/typography';
@import '../../../sass/base/common';

.orderStatus {
  background-color: white;
  padding: 24px;
  transition: all 0.5s ease-out;
  @include card;

  &-le {
    @include legalEntityComponentDTSShadow();
  }

  .title {
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 16px;

    &-le {
      @include legalEntityCustomerBoxStyling();
    }
  }

  .componentStyle {
    .labelClass {
      font-size: 14px;
    }

    p {
      font-size: 14px;
    }

    .boxClass {
      margin-bottom: 20px;
      width: 100%;
    }

    .statusIcon {
      svg {
        g {
          fill: $color_warning;
        }
      }
    }
  }

  .bottom-row {
    letter-spacing: 0.26px;
    height: 26px;

    .moreLink {
      cursor: pointer;
      margin-left: auto;
      text-decoration: none;
      &::after {
        padding-left: $width_spaceBeforeLinkMarker;
      }

      &-le--dts {
        text-transform: uppercase;
        .moreButtonWithIcon {
          font-weight: 700 !important;
        }
      }
    }

    .bottom-row__link {
      &-le {
        @include bottomLinkCustomerContractStyling();
        @include legalEntityStatusIconPathStyling();
      }

      .moreButtonWithIcon {
        display: flex;
        align-items: center;

        &-le {
          @include legalEntityComponentInvertedLinkWithIconStyling();
        }

        svg {
          margin-left: 4px;
        }

        &-icon-le--nfon {
          width: $fontsize_nfon_medium;
        }
      }
    }
  }
}
