@import './dimensions';
@import './common';
@import './legal_entities';

.moreLink {
  margin-left: auto;
  margin-top: $height_spaceAboveMoreLink;
  text-decoration: none;
  &::after {
    content: '›';
    padding-left: $width_spaceBeforeLinkMarker;
  }
}

.subheading {
  margin-bottom: $height_bigSpace;
}

.columnContainer {
  display: flex;
  max-width: $width_main;
  margin: 0 auto;
  flex-flow: nowrap;
  gap: 1rem;
  .column {
    &:first-child {
      margin-left: 0;
      flex-grow: 1;
      max-width: $width_main - $width_aside - $dimension_boxSpace;
    }
    &:not(:first-child) {
      flex-basis: $width_aside;
      //margin-left: $dimension_boxSpace;
    }
  }
}

.bigFigure {
  font-size: $fontsize_h1;
  font-weight: 500;
}

.footnote {
  font-size: $fontsize_small;
  margin-top: $height_mediumSpace;
  &::before {
    content: '* ';
  }
}

.isLoading {
  opacity: 0.3;
  transition: opacity 0.5s linear;
  position: relative;
  @include hasSpinner;
  pointer-events: none;
}

.error {
  color: $color_error;
  margin-bottom: 1rem;
}

.success {
  color: $color_success;
  margin-bottom: 1rem;
}
