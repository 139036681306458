@import "../../portal/sass/base/dimensions";
@import "../../portal/sass/base/common";
@import "../../portal/sass/base/colors";
@import "../../portal/sass/base/legal_entities";

.downloadTable {
  th:first-child,
  td:first-child {
    width: $dimension_checkbox;
  }

  td {
    &:nth-child(2) {
      width: 15% !important;
    }
  }

  th:last-child,
  td:last-child {
    white-space: nowrap;
    text-align: right;
  }

  a {
    @include hasIcon;
    display: inline-block;
    text-decoration: none;

    & + a {
      margin-left: $width_marginDefault;
    }

    svg:first-child {
      margin-right: 2px;
    }

  }

  &-title {
    margin: 25px 0;
    line-height: 1.36;
  }
}

.actions {
  margin-top: $height_bigSpace;
  display: flex;
  justify-content: space-between;

  .buttonsContainer {
    display: flex;

    & > * {
      margin-left: $dimension_boxSpace;

      &:first-child {
        margin-left: auto;
      }
    }
  }
}

.downloadLink {
  display: inline-flex !important;
  align-items: center;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }

  &-le {
    @include legalEntityTableFirstChildFontColor();

    &-dts {
      font-weight: 500 !important;
    }
  }
}

