@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/legal_entities";

.licenseTable {
  @include box;
  @include card;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .status {
    @include hasIcon;
  }


  .tableFirstChild {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;

    &-font-le {
      @include legalEntityTableFirstChildFontColor();
    }
  }

  .paginatedTable {
    padding-top: 1rem;
  }

  td {
    &:nth-child(1) {
      width: 40% !important;
    }

    &:nth-child(2) {
      width: 30% !important;
    }

    &:nth-child(3) {
      width: 25% !important;
    }

    &:nth-child(4) {
      width: 15% !important;
      text-align: center;
    }
  }

  .buttonIcons {
    display: flex;
    gap: 8px;
  }

  th {
    .cpxButton {
      margin: 0 auto;
    }
  }

}

.licenseTable-icon-le {
  @include legalEntityIconsSecondaryStyling();
}
