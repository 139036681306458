@import "../base/colors";
@import "../base/typography";

table {
  border-spacing: 0;
  width:100%;

  caption {
    font-size: $fontsize_h4;
    font-weight: 500;
    text-align: left;
    margin-bottom: $height_bigSpace;
  }

  thead {
    th {
      text-align: left;
      font-size: $fontsize_small;
      font-weight: 300;
    }
  }

  td,
  th {
    padding: calc(#{$dimension_cellSpacing} / 2);
    vertical-align: top;
    line-height: $lineheight_tableHeader;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  tbody {
    td {
      border-top: $height_tableLines solid $color_tableLines;
    }

    tr:first-child td {
      border-top: $height_tableLines solid $color_tableLineTop;
    }
  }
}
