@import '../../portal/sass/base/colors';
@import '../../portal/sass/base/legal_entities';
@import '../../portal/sass/base/common';

.box {
  padding: 16px;
  cursor: pointer;
  @include card;

  &-le {
    @include legalEntityModalBoxes();
  }

  .modalBox {
    p {
      margin-top: .5rem;
      margin-bottom: 1rem;
    }

    .modalBoxInputField {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .modalBoxFirstChild {
        display: flex;
        align-items: center;

        svg {
          margin-top: 25px;
        }
      }

      .input {
        margin-bottom: unset;
      }

      .labelInput {
        margin-left: 10px;
      }

      .modalBoxInput {
        margin-left: 10px;
        width: 190px;
        height: 48px;
        padding: 8px 10px 8px 12px;
        border-radius: 2px;
        background-color: #ffffff;
        font-size: 18px;

        &-le {
          @include legalEntityModalBoxInput();
        }
      }
    }
  }

  &.disabled {
    cursor: default;

    &-le {
      @include legalEntityDisabledSelectBoxBackground();
    }

    h4,
    p,
    span {
      opacity: .5;
    }

    svg {
      filter: saturate(0);
    }
  }
}
