@import "../../portal/sass/base/colors";
@import "../../portal/sass/base/typography";
@import "../../portal/sass/base/dimensions";
@import 'src/portal/sass/base/legal_entities';

.textWithLabel {
  margin-bottom: 1rem;
  position: relative;
  padding: 0;
  box-sizing: border-box;

  span {
    line-height: 1.25rem;
    font-size: $fontsize_small;
    margin-bottom: 4px;
    display: block;
  }
}
