@import 'src/portal/sass/base/legal_entities';
@import 'src/portal/sass/base/typography';

.infrastructureBox {
  width: 163px;
  height: 104px;

  .infrastructureBox__amount {
    line-height: 72px;


  }

  .infrastructureBox__label {
    display: flex;
    align-items: center;

    &-le {
      @include labelInfrastructureBoxStyling();
    }

    &-text {
      margin-left: 8px;
    }

  }


  &:nth-child(-n+2) {
    margin-right: 64px;
  }


}