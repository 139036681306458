@import "src/portal/sass/base/legal_entities";

.partnerContactItem {
  &:first-child {
    padding-bottom: 1rem;
  }

  &-title {
    margin: .5rem 0;
    display: flex;
    gap: .5rem;
  }

  &-subtitle {
    font-weight: 700;
    margin-bottom: 0.5rem;
  }


  &-information p {
    overflow-wrap: anywhere;
  }

}