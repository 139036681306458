@use "sass:math";
@import "./dimensions";
@import "./typography";
@import "./loadingSpinner";

html {
  height: 100%;
}

#root {
  height: 100%;
  display: grid;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.56;
  height: 100%;
}

article {
  position: relative;
  max-width: 65.25rem;
}

div, span, p, td, th, tr{
  overflow-wrap: break-word;
}

@mixin box {
  padding: $dimension_boxPadding;
  box-sizing: border-box;
  margin-bottom: $dimension_boxSpace;
}

@mixin card {
  background: var(--card-color);
  color: var(--text-color);
  border: 1px solid var(--card-border-color);

  h4 {
    color: var(--card-title-color) !important;
  }
}

@mixin hasBoxShadow {
  box-shadow: 0 2px 8px 0 $color_boxShadow;
}

@mixin hasBorder {
  border: $dimension_boxBorder solid $color_boxBorder;
}

@mixin hasIcon {
  & > svg {
    vertical-align: text-bottom;
    margin-right: $width_mediumIconSpace;
  }
}

@mixin primaryBackground {
  background: var(--highlight-color);
  color: $color_white;
}

@mixin detailsBox {
  @include box;
  @include hasBoxShadow;
  min-width: $width_aside;
  background-color: $color_white;
  position: fixed;
  right: 0;
  top: $height_headerTop + $height_headerBottom;
  overflow-y: auto;
  max-height: calc(100vh - #{$height_headerTop + $height_headerBottom});
  header {
    display: flex;
    justify-content: space-between;
    font-size: $fontsize_h4;
    font-weight: 500;
    margin-bottom: $height_mediumSpace;

    button {
      line-height: 1;
      padding: 0;
      border: 0;
      background: transparent;
      cursor: pointer;
    }
  }
  h4 {
    @include hasIcon;
  }
  footer {
    margin-top: $height_bigSpace;
    text-align: right;
  }
  p + h4 {
    margin-top: 1rem;
  }
  .progressBar {
    margin-top: $height_smallSpace;
  }
}

@mixin threeColumns {
  display: flex;
  margin-left: -$dimension_boxSpace;
  flex-wrap: wrap;
  align-items: stretch;
  & > * {
    flex-basis: calc(33.333% - #{$dimension_boxSpace});
    margin-left: $dimension_boxSpace;
  }
}

@mixin buttonInDisguise {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  cursor: pointer;
  svg {
    vertical-align: middle;
  }
}

@mixin popup {
  position: absolute;
  z-index: 100;
  top: $dimension_headerUtilities + ($height_headerPopupTriangle * 1.7);
  right: 0;
  @include hasBoxShadow;
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    height: $height_headerPopupTriangle;
    width: $height_headerPopupTriangle;
    background: inherit;
    transform: rotate(45deg);
    top: -(math.div($height_headerPopupTriangle, 2));
    right: 1rem;
  }
}
