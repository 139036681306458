$width_gridCol: 6.25rem;
$width_gridGap: 1rem;
$width_gridCol1: $width_gridCol;
$width_gridCol2: ($width_gridCol * 2) + $width_gridGap;
$width_gridCol3: ($width_gridCol * 3) + ($width_gridGap * 2);
$width_gridCol4: ($width_gridCol * 4) + ($width_gridGap * 3);
$width_gridCol5: ($width_gridCol * 5) + ($width_gridGap * 4);
$width_gridCol6: ($width_gridCol * 6) + ($width_gridGap * 5);
$width_gridCol7: ($width_gridCol * 7) + ($width_gridGap * 6);
$width_gridCol8: ($width_gridCol * 8) + ($width_gridGap * 7);
$width_gridCol9: ($width_gridCol * 9) + ($width_gridGap * 8);
$width_gridCol10: ($width_gridCol * 10) + ($width_gridGap * 9);
$width_gridCol11: ($width_gridCol * 11) + ($width_gridGap * 10);
$width_gridCol12: ($width_gridCol * 12) + ($width_gridGap * 11);
$width_gridFull: $width_gridCol12;

$width_marginDefault: 2 * $width_gridGap;
$height_marginDefault: 2.625rem;

$height_select: 2.25rem;
$width_selectPadding: 0.57rem;

$width_logo: 9.375rem;
$height_logo: 9.375rem;
$padding_logo: 0rem;
$width_logo_dts: 14.75rem;
$height_logo_dts: 6.375rem;
$padding_logo_dts: 0rem 2rem;
$height_logoGap: 1.875rem;
/*$height_logoGap_nfon: 3.875rem;*/
$width_logoGapRight: 1.25rem;
$dimension_headerUtilities: 2.25rem;
$height_languageButton: 3rem;
$width_languageButton: 3.75rem;
$width_headerUtilitiesSpacing: 1.5rem;
$dimension_avatar: 2.5rem;
$height_headerPopupTriangle: 0.5rem;
$dimension_popupPadding: 0.75rem;
$height_popupNavigationItemsPadding: 0.5rem;
$height_toastSpaceFromTop: 2.85rem;
$dimension_toastIconSpace: 1rem;
$dimension_toastIcon: 1.5rem;
$dimension_toastBorderRadius: 0.5rem;
$dimension_toastBorderWidth: 1px;
$dimension_buttonBorderRadius: 2px;

$width_navigation: $width_gridCol3;
$width_main: $width_gridCol9 + $width_gridGap;

$height_headerTop: 5.625rem;
/*$height_headerTop_nfon: 8.125rem;*/
$height_headerBottom: (2 * $height_logoGap) + $height_logo - $height_headerTop;

$height_footerMargin: 1rem;

$width_loginImageMargin: -0.3rem;
$width_loginImageMargin_dts: 2.375rem;

$width_indentNav1: 1rem;
$width_indentNav2: 3rem;
$height_spaceNav1: 0.625rem;
$height_spaceNav2: 0.125rem;
$height_paddingNav: 0.3125rem;
$width_aside: 21rem;

$dimension_boxSpace: 1rem;
$height_spaceHeadline: 0;
$height_spaceAboveMoreLink: 3.5rem;
$width_spaceBeforeLinkMarker: 0.75rem;
$dimension_boxPadding: 1.5rem;
$height_bigSpace: 2rem;
$height_mediumSpace: 1.5rem;
$height_mediumSmallSpace: 1rem;
$height_smallSpace: 0.625rem;
$height_miniSpace: 0.25rem;
$width_mediumIconSpace: 0.5em;
$width_iconSpace: 0.75em;
$width_toggleIconSpace: 0.25em;
$height_headingInBoxSpace: 0;

$height_customerLogo: 9.5rem;
$width_customerLogo: 9.5rem;

$height_sumLine: 0.125rem;
$dimension_cellSpacingSmall: 0.5rem;
$dimension_cellSpacing: 1.5rem;
$height_tableLines: 1px;
$lineheight_tableHeader: 1.75rem;
$width_tableButtonsPadding: 0.5rem;
$width_paginationLabelSpace: 0.75rem;
$width_paginationInfoSpace: 2rem;
$height_paginationSpace: 1.25rem;
$width_paginationMax: 21rem;

$width_contractTable_col1: 31%;
$width_contractTable_col2: 23%;
$width_contractTable_col3: 25%;
$width_contractTable_col4: 16%;
$width_contractTable_col4_: 21%;
$width_contractTable_col5: 20%;

$width_licenseTable_col1: 43%;
$width_licenseTable_col2: 26%;
$width_licenseTable_col3: 26%;
$width_licenseTable_col4: 16%;

$dimension_checkbox: 1.5rem;
$height_checkboxOffset: 0.1875rem;
$width_spaceAfterCheckbox: 1rem;
$dimension_checkboxThickness: 1px;
$dimension_checkboxCheckmarkThickness: 2px;
$dimension_checkboxBorderRadius: 2px;

$width_borderActiveTableRow: 3px;
$width_paddingActiveTableRow: 1rem;

$height_progressBar: 1.1rem;
$dimension_boxBorder: 1px;

$width_inputMax: 30rem;

$height_passwordStrengthBar: 0.1875rem;
$dimension_passwordStrengthPadding: 0.75rem;
$width_criteriaHeadingSpace: 1em;

$height_inputPadding: 0.25rem;
$width_inputPadding: 0.75rem;
$width_passwordHideButton: 3rem;


$width_helpBoxMin: 25rem;
$height_helpBoxMax: 12rem;

$width_helpBoxPadding: 1rem;
$height_helpBoxPadding: 1.125rem;
$height_helpBoxBottom: 3.5rem;

$dimension_modalPadding: 1.5rem;
$width_modal: 37rem;

$width_switch: 2rem;
$height_switch: 1rem;
$width_switch_text: 130px;
$width_switch_text_big: 175px;
$height_switch_text: 30px;

