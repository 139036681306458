@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/legal_entities";

.reduceLicensesModal {
  &-input-wrapper {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;

    .inputWithLabel {
      flex: 1;
    }
  }

  .reduceInfo {
    margin-bottom: 0;
  }
}