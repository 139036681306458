@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/dimensions";
@import "src/portal/sass/base/legal_entities";

.mainContractDetails {
  @include box;

  &-contractName-le {
    @include legalEntityComponentSecondaryStyling();
    @include legalEntityBodyFontSize();
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $height_bigSpace;
  }


  .column-title-le {
    @include legalEntityCustomerDataLabelStyling();
  }

  .column-title-le--dts {
    color: $color_darkGrey !important;
  }
}
