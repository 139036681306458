@import "../../portal/sass/base/colors";
@import "../../portal/sass/base/typography";
@import "../../portal/sass/base/dimensions";
@import 'src/portal/sass/base/legal_entities';

.phoneInputWithLabel {
  margin-bottom: 1rem;
  position: relative;
  padding: 0;
  box-sizing: border-box;

  .phoneInputGrid {
    display: grid;
    grid-template-columns: 1fr 2fr 7fr;
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0px;

    #countryCode, #areaCode, #cli {
      width: initial !important;
      margin: initial !important;
    }
  }

  label {
    line-height: 1.25rem;
    font-size: $fontsize_small;
    margin-bottom: 4px;
    display: block;
  }

  .label {
    &-le {
      @include legalEntityLoginLabelStyling();
    }
  }

  .required {
    &::after {
      content: " *";
    }
  }

  &:not(.phoneInputGrid) {
    #countryCode {
      width: 88px;
      margin-right: 8px;
    }

    #areaCode {
      margin: 0px 8px;
      width: 122px;
    }

    #cli {
      width: 238px;
      margin-left: 8px;
    }
  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .input {
    display: inline-block;
    box-sizing: border-box;
    line-height: 1.4;
    padding: $height_inputPadding $width_inputPadding;

    &::placeholder {
      font-size: 11px;
    }
    &-le {
      @include legalEntityInputStyling();
    }

    &:required ~ label::after {
      content: " *";
    }
  }

  .error {
    &-le {
      @include legalEntityInputBorderErrorStyling();
    }

    &:focus-visible {
      outline: none !important;
    }
  }


  .error-msg {
    margin-top: .5rem;
    display: block;
    margin-bottom: 1rem;
  }
}
