@import '../../portal/sass/base/colors';
@import '../../portal/sass/base/typography';
@import '../../portal/sass/base/dimensions';
@import '../../portal/sass/base/legal_entities';

.cpxButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: none;

  &-le {
    @include legalEntityButtonStyling();
  }

  max-width: 100%;
  cursor: pointer;
  vertical-align: baseline;
  color: $color_white;
  padding: 8px 12px;

  &.withIcon {
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  &.secondary {
    background: unset;

    &:hover:not(:disabled) {
      background: unset;
    }

    &-le {
      @include legalEntityButtonSecondaryStyling();
    }
  }

  &.white {
    background: var(--color-white);
    border: 1px solid var(--color-white);
    color: var(--color-black);

    &-le--dts {
      color: var(--title-color);
    }

    &:hover {
      background: none !important;
      color: white;
    }
  }

  &.white-override {
    color: $color_white !important;
  }

  &.error {
    background: $color_error;

    &:hover:not(:disabled) {
      background: $color_errorHover;
    }
  }

  &.linkStyle {
    background: transparent;
    text-transform: none;
    font-size: inherit;
    padding: 0;

    &:hover {
      background: transparent;
    }

    &::after {
      content: '›';
      padding-left: $width_spaceBeforeLinkMarker;
      font-size: $fontsize_medium;
    }

    &-link-le {
      @include legalEntityComponentInvertedLinkStyling();
    }
  }

  &.iconOnly {
    &,
    &:hover {
      background: none !important;
      padding: 0;
      vertical-align: middle;
    }
  }

  &.moreLink {
    margin-left: auto;
    text-decoration: none;
    background: none;

    &-le {
      @include legalEntityComponentInvertedLinkStyling();
      @include legalEntityContrastLinkColor();
    }

    span {
      color: var(--primary-color);
    }

    &::after {
      content: '›';
      padding-left: $width_spaceBeforeLinkMarker;
      color: var(--primary-color);
    }

    &:hover {
     background: none;
    }

    &.white-override {
      span,
      &::after {
        color: var(--color-white);
      }
    }
  }
}

.buttonIcons {
  gap: .5rem;
}
