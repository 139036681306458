@import "./themes";
@import "./colors";
@import "./dimensions";

$fontsize_h1: 4rem; // 64px
$fontsize_h2: 3rem; // 48px
$fontsize_h3: 2.25rem; // 36px
$fontsize_h4: 1.375rem; // 22px
$fontsize_body: 1.125rem; // 18px
$fontsize_medium: 1rem; // 16px
$fontsize_small: 0.875rem; // 14px
$fontsize_tiny: 0.75rem; // 12px

$fontsize_nfon_h1: 4rem; // 64px
$fontsize_nfon_h2: 2.5rem; // 40px
$fontsize_nfon_h3: 2.25rem; // 36px
$fontsize_nfon_h4: 1.25rem; // 20px
$fontsize_nfon_body: 1.125rem; // 18px
$fontsize_nfon_medium: 1rem; // 16px
$fontsize_nfon_small: 0.875rem; // 14px
$fontsize_nfon_tiny: 0.75rem; // 12px


/* font families */
$font_family_nfon: "PPPangramSans";
$font_family_nfon_secondary: "Nunito";
$font_family_dts: "FiraSans"´;
$font_family_tef: "PPPangramSans";

/* font weights */
$font_weight_light: 300;
$font_weight_regular: 400;
$font_weight_medium: 500;
$font_weight_bold: 700;

/* font sizes */
// NFON
$new_fontsize_nfon_h1: 3rem; // 48px
$new_fontsize_nfon_h2: 2.5rem; // 40px
$new_fontsize_nfon_h3: 2.25rem; // 36px
$new_fontsize_nfon_h4: 1.25rem; // 20px
$new_fontsize_nfon_h5: 1rem; // 16px
$new_fontsize_nfon_body: 1rem; // 16px
$new_fontsize_nfon_body_small: 0.875rem; // 14px

// DTS
$new_fontsize_dts_h1: 4rem; // 64px
$new_fontsize_dts_h2: 3rem; // 48px
$new_fontsize_dts_h3: 2.25rem; // 36px
$new_fontsize_dts_h4: 1.375rem; // 22px
$new_fontsize_dts_h5: 1.125rem; // 18px
$new_fontsize_dts_body: 1.125rem; // 18px
$new_fontsize_dts_body_small: 0.875rem; // 14px

// TEF
$new_fontsize_nfon_h1: 4rem; // 64px
$new_fontsize_nfon_h2: 3rem; // 48px
$new_fontsize_nfon_h3: 2.25rem; // 36px
$new_fontsize_nfon_h4: 1.25rem; // 20px
$new_fontsize_nfon_h5: 1rem; // 16px
$new_fontsize_nfon_body: 1rem; // 16px
$new_fontsize_nfon_body_small: 1rem; // 14px

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: $height_spaceHeadline;
}

h1,
h2,
h3 {
  margin-top: 0;
}

h1 {
  font: var(--typo-h1);
}

h2 {
  font: var(--typo-h2);
}

h3 {
  font: var(--typo-h3);
}

h4 {
  font: var(--typo-h4);
}

h5 {
  font: var(--typo-h5);
}

p,
input,
select,
option,
tbody {
  font: var(--typo-body);
  line-height: unset !important;
}

label,
button {
  font: var(--typo-body-small);
}

h4,
h5,
h6 {
  margin-top: 0;
}

p {
  margin: 0;
}

a {
  color: var(--button-bg-color);
  text-decoration: none;
  font-weight: 700;

  &:hover {
    color: var(--button-bg-hover-color);
    text-decoration: underline;
  }

  &:active {
    color: var(--button-active-bg-color);
  }
}
