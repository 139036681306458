@import 'src/portal/sass/base/legal_entities';
@import 'src/portal/sass/base/typography';
@import 'src/portal/sass/base/common';

$rowMargin: 40px;
$rowLength: 642px;

.customerContract {
  padding: 24px;
  @include card;

  .title {
    line-height: 1.36;
    padding-bottom: 25px;

    &-le {
      @include legalEntityCustomerBoxLineStyling();
    }
  }

  .label-row {
    margin-top: $rowMargin;
    line-height: 30px;
  }

  .package-row {

    &-le {
      @include packageRowStyling();
    }
  }

  .detail-row {
    display: flex;
    width: $rowLength;
    height: 52px;
    margin-top: $rowMargin;
  }

  .infrastructure-row {
    display: flex;
    padding-bottom: 24px;
    width: $rowLength;
    height: 124px;
    margin-top: $rowMargin;

    h1 {
      font-size: 4rem;
    }
  }

  .bottom-row {
    text-align: right;
    margin-top: $rowMargin;
    letter-spacing: 0.26px;
    height: 26px;
    width: $rowLength;

    .bottom-row__link {
      &-le {
        @include legalEntityComponentInvertedLinkStyling();
        @include bottomLinkCustomerContractStyling();
      }
    }
  }
}
