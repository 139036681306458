@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/legal_entities';

.customerData {
  @include box;
  @include card;
  align-items: stretch;

  .column {
    flex-basis: 33.333%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    &:first-child {
      flex-basis: 66.666%;
      box-sizing: border-box;
      flex-flow: row;
      flex-wrap: wrap;

      & > * {
        flex-basis: 50%;
      }

      & > h4 {
        flex-basis: 100%;
        margin-bottom: $height_mediumSpace;
      }
    }
  }

  .moreLink {
    margin-top: 0 !important;
  }

  h5 {
    padding-top: 1rem;
    @include hasIcon;

    &.customerData-label-le {
      @include legalEntityCustomerDataLabelStyling();
    }
  }

  .longEmail {
    word-break: break-all;
  }
}
