@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/legal_entities";

.extensionTable {
  @include box;

  &-le {
    @include legalEntityComponentStyling();
  }

  .status {
    @include hasIcon;
  }

  .highlighted {
    @include buttonInDisguise;
    color: $color_primary_nfon;
    font-weight: 500;
  }

  td {
    @include hasIcon;

    &:nth-child(1) {
      width: 20% !important;
    }

    &:nth-child(2) {
      width: 20% !important;
    }

    &:nth-child(3) {
      width: 20% !important;
    }

    &:nth-child(4) {
      width: 10% !important;
    }

    &:nth-child(5) {
      width: 10% !important;
    }
  }

  .tableFirstChild {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: $color_primary_nfon;

    &-font-le {
      @include legalEntityTableFirstChildFontColor();
      @include legalEntityBodyFontSize();
    }
    div {
      overflow-wrap: anywhere;
    }
  }
}

.extensionsTable-icon-le {
  @include legalEntityPrimaryPathStyling();
}

.extensionsTable-search-icon-le {
  @include legalEntityIconsPrimaryFontColorStyling();
}

.extensionsTable-actionable-icon-le {
  //@include legalEntityIconsStylingTemp();
}
