@import '../../../../../sass/base/common';
@import '../../../../../sass/base/legal_entities';

.contactAccount {
  @include box;
  @include card;

  .titleBox {
    margin-bottom: $height_mediumSpace;

    &-le {
      @include legalEntityCustomerContractPersonTextStyling();
    }
  }

  .contactAccountBox {
    display: flex;
    justify-content: space-between;

    section {
      width: 269px;
    }
  }

  h3,
  h4 {
    display: flex;
    align-items: center;

    &.componentTitle-le {
      @include legalEntityCustomerDataLabelStyling();
    }
  }

  a {
    text-decoration: none;
  }
}
