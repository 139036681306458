@import "../../portal/sass/base/colors";
@import "../../portal/sass/base/typography";
@import "../../portal/sass/base/dimensions";
@import 'src/portal/sass/base/legal_entities';


.admin-portal-user {
  &:not(:first-child) {
    margin-top: 38px;
  }

  .line-white {
    &:before {
      content: "";
      display: block;
      height: 0.0625rem;
      background: white;
    }
  }
  .line-grey {
    &:before {
      content: "";
      display: block;
      height: 0.0625rem;
      background: $color_box_border_nfon;
    }

  }
  .sub-title {
    margin-top: 32px;
    margin-bottom: 32px;
    &-le {
      @include legalEntityBodyFontSize();
    }
  }

  .mobilePhoneInfo {
    font-size: $fontsize_small;
    color: var(--neutral-color);
    margin-top: -.5rem;
    margin-bottom: 1.5rem;
  }

}

.commercialPortalUserInputGroup {
  &-switch {
    &--wrapper {
      font-size: 16px;
      display: flex;
      gap: 16px;
      align-items: baseline;
      margin-bottom: 2rem;
    }
  }
}