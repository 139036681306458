@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/typography';
@import 'src/portal/sass/base/legal_entities';

.telephoneConnection {
  @include box;
  @include card;

  .desc {
    color: var(--text-color);
    font-weight: 300 !important;
  }

  table {
    caption {
      text-align: left;
      &.telcon-caption-le {
        @include legalEntityTableFirstChildStyling();
      }
    }

    .startdate, .enddate {
      @include hasIcon;
      border-top: unset;
    }

    td {
      @include hasIcon;
      border-top: unset;

      &:nth-child(1) {
        width: 40% !important;
      }

      &:nth-child(2) {
        width: $width_contractTable_col1 !important;
      }

      &:nth-child(3) {
        width: $width_contractTable_col3;
      }

      &:nth-child(4) {
        width: $width_contractTable_col4;
      }
    }

    .startdate,
    .enddate {
      white-space: nowrap;
    }
  }

  tbody {
    tr:first-child {
      &.telephoneConnection-firstRow-le {
        @include legalEntityFirstTableLinesStyling();
      }
    }
  }
}
