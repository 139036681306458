@import "./colors";
@import "./fonts";

:root {
    --color-white: #FFFFFF;
    --color-black: #000000;
    .app-le- {
            &-nfon,
            &-tef {
                    /* colors */
                    --primary-color: #00FF91;
                    --hover-color: #1A1A1A;
                    --bg-color: linear-gradient(180deg, rgba(51,51,51,1) 0%, rgba(24,24,24,1) 90%);
                    --bg-contrast-color: #000000;
                    --card-color: #303030;
                    --card-title-color: var(--color-white);
                    --card-border-color: #666666;
                    --highlight-color: #3200CB;
                    --error-color: #FF0000;
                    --success-color: #52FFB4;
                    --warning-color: #F99505;
                    --neutral-color: #969696;
                    --title-color: #FFFFFF;
                    --subtitle-color: #FFFFFF;
                    --text-color: #FFFFFF;
                    /* button styling */
                    --button-bg-color: var(--primary-color);
                    --button-bg-hover-color: #52FFB4;
                    --button-disabled-bg-color: #666666;
                    --button-active-bg-color: #00D67A;
                    --button-text-color: #000000;
                    --button-text-hover-color: #000000;
                    --button-text-transform: none;
                    --button-border: none;
                    --button-border-radius: unset;
                    --button-padding: 8px 12px;
                    /* input styling */
                    --input-bg-color: #4A4A4A;
                    --input-color: #FFFFFF;
                    --input-border: 1px solid #666666;
                    --input-border-radius: 1px;
                    /* typography */
                    --typo-h1: 400 3rem "PPPangramSans"; // 48px
                    --typo-h2: 400 2.5rem "PPPangramSans"; // 40px
                    --typo-h3: 400 2.25rem "PPPangramSans"; // 36px
                    --typo-h4: 700 1.25rem "PPPangramSans"; // 20px
                    --typo-h5: 700 1rem "PPPangramSans"; // 16px
                    --typo-body: 400 1rem "Nunito"; // 16px
                    --typo-body-small: 400 0.875rem "Nunito"; // 14px
                    --typo-button: 700 0.875rem "PPPangramSans"; // 14px
            }
            &-dts {
                    /* colors */
                    --primary-color: #004D7E;
                    --hover-color: #F3F6F8;
                    --bg-color: #F0EEE4;
                    --bg-contrast-color: #FFFFFF;
                    --card-color: #FFFFFF;
                    --card-title-color: var(--primary-color);
                    --card-border-color: #FFFFFF;
                    --highlight-color: #FFC600;
                    --error-color: #E22800;
                    --success-color: #10BC00;
                    --warning-color: #F99505;
                    --neutral-color: #AEC6D3;
                    --title-color: var(--primary-color);
                    --subtitle-color: #323232;
                    --text-color: #323232;
                    /* button styling */
                    --button-bg-color: #004D7E;
                    --button-bg-hover-color: #3670A0;
                    --button-disabled-bg-color: #9AB7C1;
                    --button-active-bg-color: #004D7E;
                    --button-text-color: #FFFFFF;
                    --button-text-hover-color: #FFFFFF;
                    --button-text-transform: uppercase;
                    --button-border: none;
                    --button-border-radius: 2px;
                    --button-padding: 10px 12px;
                    /* input styling */
                    --input-bg-color: #FFFFFF;
                    --input-color: var(--primary-color);
                    --input-border: 1px solid #555555;
                    --input-border-radius: 1px;
                    /* typography */
                    --typo-h1: 400 4rem "FiraSans"; // 64px
                    --typo-h2: 400 3rem "FiraSans"; // 48px
                    --typo-h3: 400 2.25rem "FiraSans"; // 36px
                    --typo-h4: 500 1.375rem "FiraSans"; // 22px
                    --typo-h5: 500 1.125rem "FiraSans"; // 18px
                    --typo-body: 400 1.125rem "FiraSans"; // 18px
                    --typo-body-small: 400 0.875rem "FiraSans"; // 14px
                    --typo-button: 400 0.75rem "FiraSans"; // 12px
            }
    }
}

