@import '../../../sass/base/common';
@import "src/portal/sass/base/legal_entities";



.quotesName {
  .inputWithLabel {
    display: flex;

    input {
      flex: 1;
      margin-right: 1rem;
    }

    .buttons {
      display: flex;
      gap: 1rem;
      justify-content: space-between;

      svg {
        g, path {
          fill: var(--primary-color);
        }
      }
    }
  }
}

.quoteDetail {

  .quotesTitle {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 2rem;
    h2 {
      flex: 1;
    }
  }

  .article-box {
    @include box;
    @include card;
  }
  .edit-buttons {
    margin-top: 2rem;
    display: flex;
    button {
      margin-left: 1rem;
      .buttonIcons {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 36px;
        padding-left: 6px;

        p {
          margin-left: 8px;
        }
      }
    }
    svg {
      g, path {
        fill: var(--button-text-color);
      }
    }
  }
  .message-title{
    margin: 2rem 0 0.5rem 0;
  }

  &-downloadDocument {
    margin-bottom: 1.5rem;
  }

  .message {
    @include card;
    padding: 1rem;
    margin-bottom: 2rem;
    white-space: pre-line;
  }

  &-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    &-le {
      @include legalEntityStatusIconPathStyling();
    }
  }

  .quote-documents-table {
    table {
      padding-top: 1rem;
    }
    td {
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 40%;
      }

      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 10%;
      }
    }

    padding-bottom: 1rem;
  }

  &-cross-btn {
    &-le {
      @include legalEntityActiveFontColor();
    }
  }

  .send-quote{
    float: right;
  }
  .sum-row-le {
    @include legalEntityChargesSumStyling();
  }

  .account-data {
    p {
      margin-top: 1rem;
    }
    padding-bottom: 1rem;
  }

}
