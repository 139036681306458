@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/typography";
@import "src/portal/sass/base/dimensions";
@import "src/portal/sass/base/legal_entities";
@import "src/portal/sass/base/themes";

.header {
  width: 100%;
  min-width: $width_gridFull;

  &-wrapper {
    margin-bottom: $height_headerBottom;
  }

  &-le {
    @include legalEntityHeaderStyling();
  }

  .container {
    position: relative;
    width: $width_gridFull;
    margin: 0 auto;
    display: flex;
    flex-flow: wrap;
    .logo {
      margin-right: $width_logoGapRight;
      &-le {
        @include legalEntityLogoStyling();
      }
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-weight: 500;
      font-size: $fontsize_h4;
      line-height: 1;

      &-le {
        @include legalEntityHeaderLogo();
      }
    }
    .utilities {
      margin-left: auto;
      & ~ .utilities {
        margin-left: $width_headerUtilitiesSpacing;
      }
    }
  }
}
