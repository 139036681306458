@import "../../portal/sass/base/dimensions";
@import "../../portal/sass/base/colors";
@import "../../portal/sass/base/legal_entities";

.switchTextWrapper {
  display: block;
  position: relative;
  cursor: pointer;

  input {
    display: none;
  }

  .switch-text {
    display: block;
    width: $width_switch_text;
    height: $height_switch_text;
    position: relative;
    cursor: pointer;

    &-le {
      @include legalEntitySwitchWithTextStyling();

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: $width_switch_text;
        height: $height_switch_text;
        box-sizing: border-box;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 60px;
        height: $height_switch_text;
        transition: all 0.2s ease-in-out;
        border-radius: 100px;
      }

      &.active {
        &::after {
          width: 74px;
          left: $width_switch_text - 74px;
        }
      }

      &.en {
        width: $width_switch_text_big;

        &::before {
          width: $width_switch_text_big;
        }

        &::after {
          width: 105px;
          transition: all 0.2s ease-in-out;
        }

        &.active {
          &::after {
            width: 75px;
            left: $width_switch_text_big - 75px;
          }
        }
      }
    }
  }

  .switch-text-left,
  .switch-text-right {
    &-le {
      @include legalEntitySwitchWithTextParagraphStyling();
    }
  }

  .switch-text-left {
    left: .8rem;
  }

  .switch-text-right {
    right: .7rem;

    &.big {
      right: .6rem;
    }
  }


}

