@import "../../portal/sass/base/dimensions";
@import "../../portal/sass/base/colors";
@import "../../portal/sass/base/legal_entities";

.progressBar {
  position: relative;
  height: $height_progressBar;

  &-le {
    @include legalEntityProgressbarStyling();
  }

  .progressBarInner {
    position: absolute;
    height: 100%;
  }
}

.securityProgressBar {

  &-le {
    @include legalEntityProgressbarStyling();
  }

  position: relative;
  height: $height_progressBar;

  .securityProgressBarInner {
    position: absolute;
    height: 100%;

    &.overThreshold {
      background: $color_warning;
    }
  }
}
