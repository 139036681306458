@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.numbers-modal {

  &-container {
    margin: 2rem 0;
    border-bottom: 1px solid #666666;
    padding-bottom: 2rem;
  }

  &-address-wrapper {
    display: flex;
    gap: 1.5rem;

    .dropdownWithLabel {
      width: 100%;
    }

    .undocumentedAddress{
      flex: 1;
    }

  }

  &-delete-icon {
    &-le {
      @include legalEntityStatusIconPathStyling();
    }
  }

  &-areaCode-input {
    width: 116px;
    margin-bottom: 0;
  }


}