@import "../../../../../portal/sass/base/dimensions";
@import "../../../../../portal/sass/base/colors";
@import "../../../../../portal/sass/base/typography";
@import "../../../../../portal/sass/base/common";
@import "../../../../../portal/sass/base/legal_entities";


.addLicensesModal {
  .boxes {
    margin-top: 2rem;
  }

  .empty-state {
    padding: 3rem 2rem;
  }

  .paginatedTable {
    padding-top: 0;

    td {
      &:nth-child(1) {
        width: 50% !important;
      }

      &:nth-child(2) {
        width: 10% !important;
      }

      &:nth-child(3) {
        width: 10% !important;
      }

      &:nth-child(4) {
        width: 10% !important;
      }
    }
  }

  .qty {
    display: flex;
    align-items: center;
    gap: .5rem;

    .inputWithLabel {
      width: 40%;
    }

    .helpbox {
      padding-top: .25rem;
      flex: 1;

      .popup {
        min-width: 135px;
        padding: .5rem;
        bottom: 2.5rem;
      }
    }

    .helpBox-icon {
      &-le {
        @include legalEntityHelpBoxErrorIconStyling();
      }
    }
  }

  .modalTable {
    padding-bottom: 2rem;
  }
}