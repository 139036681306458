@import "../../../../../sass/base/legal_entities";

.orderCallNumberSummary {

  &-section-subTitle-le {
    @include legalEntityBodyFontSize();
  }

  &-hr {
    margin: 2rem 0;

    &-le {
      @include legalEntityLineColorDark();
    }
  }

  &-discountInfo {
    margin-top: 2rem;
  }
}