@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.personInChargeList {
  @include box;
  @include card;

  h3 {
    margin-bottom: $height_mediumSpace;

    &.componentTitle-le {
      @include legalEntityCustomerDataLabelStyling();
    }
  }

  h5 {
    margin-bottom: unset !important;
  }

  a {
    text-decoration: none;
  }
}
