@import "src/portal/sass/base/common";
@import "src/portal/sass/base/dimensions";
@import "src/portal/sass/base/legal_entities";

.mainContractsTable {
  @include box;
  @include card;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .paginatedTable {
    padding-top: 0;
  }
  
  &-knumber {
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;

    &-le--dts {
      font-weight: 500 !important;
    }
  }

  td {
    &:nth-child(1) {
      width: 15% !important;
    }

    &:nth-child(2) {
      width: 30% !important;
    }

    &:nth-child(3) {
      width: 15% !important;
    }

    &:nth-child(4) {
      width: 30% !important;
    }
  }

  &-notes {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 220px;
      height: 1.2em;
      white-space: nowrap;

      span {
        opacity: 0.5;
      }
    }

    .inputWithLabel {
      flex: 1;

      input {
        margin-top: 1rem;
      }
    }

  }

  &-cross-btn {
    margin-left: -.5rem;
    &-le {
      @include legalEntityActiveFontColor();
    }
  }

}
