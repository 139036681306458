@import '../../../../sass/base/common';
@import '../../../../sass/base/colors';
@import '../../../../sass/base/dimensions';
@import '../../../../sass/base/legal_entities';

.personInChargeList {
  @include box;
  @include card;

  h4 {
    display: flex;
    align-items: center;
    margin-bottom: $height_bigSpace !important;

    &.componentTitle-le {
      @include legalEntityCustomerDataLabelStyling();
    }
  }

  .user {
    width: 16px;
    height: 16px;
    padding: 0 5px;
    margin-right: 7px;
  }

  a {
    text-decoration: none;
  }

  .mail {
    line-break: anywhere;
  }

  .aColor {
    color: var(--primary-color);
  }
}
