@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/legal_entities';

.navigation {
  padding: $height_paddingNav 0;
  margin-bottom: $dimension_boxSpace;

  &-le {
    @include legalEntityNavigationStyling();
  }

  li {
    position: relative;
    list-style: none;

    #nav-link-my-contract,
    #nav-link-settings,
    #nav-link-adminportal,
    #nav-link-partner-customers {
      padding-left: 18px;
    }
  }

  .nav-link {
    &:hover {
      color: var(--button-bg-hover-color) !important;
      background: var(--hover-color) !important;
    }
  }

  ul {
    padding: $height_spaceNav1 0;
    margin: 0;
  }

  .navSearchButton {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 1rem;
    align-items: center;

    .nav {
      display: flex;
      align-items: center;

      .iconDefault {
        margin-right: 0.5em;
      }
    }

    .iconButton {
      cursor: pointer;
    }
  }

  .search {
    position: relative;
    padding-left: 16px;

    svg {
      position: absolute;
      z-index: 1;
      top: 23px;
      right: 27px;
      width: 16px;
    }

    .searchCustomer {
      width: 73%;
      height: 25px;
      margin-top: 16px;
      padding: 6px 49px 6px 8px;

      &-le {
        @include legalEntityModalBoxInput();
        @include legalEntityInputStyling();
      }
    }
  }

  .partner {
    padding-left: 16px;
  }

  a,
  a:link,
  a:hover,
  a:visited {
    @include hasIcon;
    text-decoration: none;
    color: inherit;
    font-weight: inherit;
    display: flex;
    align-items: center;

    &.active {
      &-le {
        @include legalEntityActiveFontColor();
        @include legalEntityActiveBackgroundColor();
      }
    }
  }

  & > ul > li {
    // padding-left: $width_indentNav1;

    &:not(:last-child) {
      margin-bottom: $height_spaceNav1;
      padding-bottom: $height_spaceNav1;

      &.nav-li-le {
        @include legalEntityNavigationSeperatorStyling();
      }
    }

    ul {
      padding: 0;
      margin-top: $height_spaceNav1;

      a {
        margin-bottom: .5rem;
      }
    }

    li {
      padding-left: $width_indentNav2;
      margin-bottom: $height_spaceNav2;
    }
  }

  .quickSearchResults {
    position: absolute;
    width: 89%;
    left: 5%;
    padding: 8px 0;
    z-index: 2;

    &-le {
      @include legalEntityNavigationQuickSearchBgColor();
    }

    .quickSearchButton {
      width: 100%;
      padding: 8px 16px;
      background: unset;
      text-align: unset;
      text-transform: unset;
      -moz-user-select: all; /* Firefox */

      &-le {
        @include legalEntityQuickSearchButton();
      }
    }
  }

  .partnerIcon {
    &-le {
      @include legalEntitySecondaryIconStyling();
    }
  }

  .crossIcon {
    position: absolute;
    top: 2px;
    right: 18px;
    padding: 2px;
    border-radius: 50%;

    &-le {
      @include legalEntityNavigationCrossIconStyling();
    }
  }
}