@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.locationsTable {
  @include box;

  td {

    &:not(:nth-child(5)) {
      overflow-wrap: anywhere;
    }

    &:nth-child(1) {
      width: 25% !important;
    }

    &:nth-child(2) {
      width: 50% !important;
    }

    /*&:nth-child(3) {
      width: $width_licenseTable_col4 !important;
    }*/

    /*&:nth-child(5) {
      width: $width_contractTable_col1 !important;
    }*/

  }

  &-le {
    @include legalEntityComponentStyling();
  }

  &-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    &-le {
      @include legalEntityStatusIconPathStyling();
    }
  }

  &-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .buttonIcons {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 36px;
      padding-left: 6px;

      p {
        margin-left: 8px;
      }
    }

  }

  &-title-le {
    @include legalEntityHeadingStylingH4();
  }

  .tableFirstChild {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;

    &-font-le {
      @include legalEntityTableFirstChildFontColor();
      @include legalEntityBodyFontSize();
    }
  }

  .paginatedTable {
    padding-top: 0;
  }
}