.quotesTitle {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.createQuote {
  td {
    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 15%;
    }

    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 15%;
    }
    &:nth-child(5) {
      width: 25%;
    }

  }
}