@import '../../../portal/sass/base/colors';
@import '../../../portal/sass/base/legal_entities';
@import '../../../portal/sass/base/typography';

.customer-container-le {
  .customer-name {
    overflow-wrap: anywhere;
    margin-right: 11px;
    color: var(--primary-color);
    font-weight: 700;

    &-le--dts {
      font-weight: 500;
    }
  }

  .customer-title {
    margin-top: -12px;;

    &-le--dts {
      color: var(--primary-color);
      font-weight: 500;
    }

    &.dts-dark-color {
      color: $color_font_dark_dts;
      font-weight: 400;
    }
  }

  .partner-customer-data-title-le--dts,
  .partner-customer-contract-title-le--dts {
    color: $color_font_dark_dts;
  }
}

h2 {
  &.title {
    &.partner-customer-overview-title-le--nfon,
    &.partner-customer-overview-title-le--dts {
      line-height: initial;
      overflow-wrap: anywhere;
      margin-left: -2px;
    }
  }
}
