@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/legal_entities';

.progress {
  height: 17px;

  &-le {
    @include legalEntityProgressbarStyling();
  }
}

.extensionsProgressBar {
  p {
    font-weight: 300;
  }

  &-le--dts {
    span {
      font-weight: 500 !important;
    }
  }

  span {
    margin: 0 8px 0 8px;
    font-weight: 700;
  }
}

.infoField {
  display: flex;
  width: 440px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  .iconWithText {
    display: flex;
    align-items: center;

    .progressBarIcon {
      margin-right: 8px;
    }
  }

  .extensionsSum {
    span {
      margin-left: 8px;
    }
  }
}

.modalExtensionsLegend {
  display: flex;
  align-items: center;
  margin-top: 8px;

  &-le--dts {
    span {
      font-weight: 500 !important;
    }
  }

  span {
    margin: 0 8px;
    font-weight: 700;
  }

  .usedExtensionsLegend {
    width: 16px;
    height: 16px;
    border-radius: 10px;
    margin-right: 8px;

    &-le {
      @include legalEntityProgressbarLegendUsedStyling();
    }
  }

  .freeExtensionsLegend {
    margin-left: 8px;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    margin-right: 8px;

    &-le {
      @include legalEntityProgressbarLegendFreeStyling();
    }
  }
}
