@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.editBox {

  padding: 24px;
  margin-bottom: 1rem;

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &-le {
   @include legalEntityEditBoxActive();;
  }

  &-title {
    &-le {
      @include legalEntityHeadingStylingH4();
    }
  }

  .button {
    cursor: pointer;
    fill: white;
  }

}