@import '../../portal/sass/base/common';
@import '../../portal/sass/base/dimensions';

.passwordInput {
  position: relative;

  input {
    padding-right: $width_passwordHideButton;
  }

  .hideButton {
    @include buttonInDisguise;
    position: absolute;
    bottom: 0;
    right: 0;
    height: ($fontsize_body * 1.4) + ($height_inputPadding * 2);
    width: $width_passwordHideButton;
    cursor: pointer;
    color: $color_primary_dts;
  }

  .hideButtonNfon {
    @include buttonInDisguise;
    position: absolute;
    bottom: 0;
    right: 0;
    height: ($fontsize_body * 1.4) + ($height_inputPadding * 2);
    width: $width_passwordHideButton;
    cursor: pointer;
    color: $color_secondary_nfon;
  }
}
