@import '../../../sass/base/common';

.panel {

  @include card();
  margin-bottom: 1rem;
  padding: 0 1rem;

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    h5 {
      flex: 1;
    }
  }

  .pointer {
    cursor: pointer;
  }

  &-content {
    padding: 0 1rem;
  }

}