@import "../../../sass/base/legal_entities";

.numbers {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &-title-le {
    @include legalEntityPageTitleStyling();
  }

  &-subtitle-le {
    @include legalEntityPageSubtitleStyling();
  }

  .buttonIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    padding-left: 6px;
    gap: .5rem;
  }

}
