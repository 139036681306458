@import '../../portal/sass/base/colors';
@import '../../portal/sass/base/typography';
@import '../../portal/sass/base/dimensions';
@import '../../portal/sass/base/common';
@import '../../portal/sass/base/legal_entities';

.paginatedTable {
  padding-top: 50px;

  &-caption-le {
    @include legalEntityCustomerDataLabelStyling();
  }

  &-tableHeader-le {
    @include legalEntityTableHeaderStyling();
  }

  &-tableFooter {
    font-weight: 700;

    &-le {
      @include legalEntityFirstTableLinesStyling();
    }
  }


  &.hasPages {
    margin-bottom: $dimension_cellSpacing;

    tbody {
      tr:last-child td.firstChild-le {
        padding-bottom: calc(#{$dimension_cellSpacing} / 2);
        @include legalEntityLastTableLinesStyling();
      }
    }
  }

  tbody {
    tr {
      &.paginatedTable-row-le {
        @include legalEntityTableLinesStyling();
      }
    }

    /*tr:first-child {
      &.paginatedTable-firstRow-le {
        @include legalEntityFirstTableLinesStyling();
      }
    }*/

    h4, td, td button {
      overflow-wrap: anywhere;
    }
  }

  td {
    @include hasIcon;

    &:nth-child(1) {
      width: $width_licenseTable_col1;
    }

    &:nth-child(1):has(.checkboxWrapper) {
      width: 7%;
    }

    &:nth-child(2) {
      width: $width_licenseTable_col2;
    }

    &:nth-child(3) {
      width: $width_licenseTable_col3;
    }

    &:nth-child(4) {
      width: $width_licenseTable_col4;
    }
  }

  td:first-child {
    padding-right: calc(
            #{$dimension_cellSpacing} + #{$width_borderActiveTableRow}
    );

    &.firstChild-le {
      @include legalEntityTableFirstChildStyling();
    }
  }

  .isActive {
    background-color: $color_activeTableRow;

    td:first-child {
      border-left: $width_borderActiveTableRow solid $color_primary_nfon;
      padding-left: calc(#{$dimension_cellSpacing} / 2);
      padding-right: calc(#{$dimension_cellSpacing} / 2);
    }
  }

  .hasClickEvent {
    cursor: pointer;
  }

  @keyframes pulsate {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }

  }

  .skeleton {
    > div {
      background-color: var(--button-disabled-bg-color);
      height: 0.5rem;
      background-clip: content-box;
      animation: pulsate 1.5s infinite;
      border-radius: 3px;
    }
    padding: 1.5rem 2rem 1.5rem 0;
  }



  &-collapsible {
    &-button {
      text-align: right;
      svg {
        cursor: pointer;
      }

      width: 10% !important;
    }

    &-row {
      td {
        padding-top: 0;
        border-top: none;
      }

      td:last-child {
        vertical-align: middle;
      }

      .cpxButton {
        &:not(.iconOnly) {
          width: 100%;
        }
      }

    }
  }
}

.paginationWrapper {
  position: relative;

  &:first-child {
    margin-bottom: $height_paginationSpace;
  }

  &:last-child {
    margin-top: $height_paginationSpace * 2;
  }
}

.numbersTable {
  td {
    &:nth-child(1) {
      width: $width_licenseTable_col4;
    }
  }
}


