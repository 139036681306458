@import "../sass/base/dimensions";

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  display: flex;
  width: $width_gridFull;
  margin: 0 auto;
  display: flex;
  flex-flow: wrap;
  animation: appear 0.5s ease-in-out forwards;

  .column {
    flex-basis: $width_main;

    &:first-child {
      flex-basis: $width_navigation;
      padding-right: $width_marginDefault;
      box-sizing: border-box;
    }
  }
  .evn {
    flex-basis: $width_main;
    margin-left: auto;
    margin-right: auto;
  }

}
