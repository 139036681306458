@import "src/portal/sass/base/dimensions";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/typography";
@import "src/portal/sass/base/legal_entities";

.notification {
  background: var(--highlight-color);
  padding: 10px;

  &-container {
    width: $width_gridFull;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 1rem;

    &-le--dts {
      padding-left: 640px;
    }
  }
}
