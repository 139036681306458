@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/legal_entities';

.partnerSipTrunkTable {
  @include box;
  background-color: $color_white;

  &-le {
    @include legalEntityComponentStyling();
  }

  .status {
    @include hasIcon;
  }

  .highlighted {
    @include buttonInDisguise;
    color: $color_primary_nfon;
    font-weight: 500;
  }

  .partnerSipTrunk {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-le {
      @include legalEntityComponentBoxHeaderIconStyling();
    }
  }

  table {
    tbody {
      .partnerSipTrunkHeader {
        &-le {
          @include legalEntityBoxHeaderStyling();
        }
      }

      td.line-le {
        padding-bottom: calc(#{$dimension_cellSpacing} / 2);
        @include legalEntityLastTableLinesStyling();
      }

      tr {

        td:first-child {

        }
      }
    }

    .partnerSipTrunkHeader {
      &-le {
        @include legalEntityBoxHeaderStyling();
      }
    }

    .component-title {
      &-le {
        @include legalEntityTableFirstChildStyling();
      }
    }

    th {
      width: 121px;
      height: 24px;
      margin: 0 97px 12px 0;
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      border-bottom: unset;
      padding-top: unset;
    }

    td {
      @include hasIcon;
      border-top: unset;
      @include legalEntityLastTableLinesStyling();

      &:nth-child(1) {
        width: 45% !important;
      }

      &:nth-child(2) {
        width: $width_contractTable_col3 !important;
      }

      &:nth-child(3) {
        width: $width_contractTable_col3 !important;
      }

      &:nth-child(4) {
        width: $width_contractTable_col4 !important;
      }
    }

    .startdate, .enddate {
      @include hasIcon;
      border-top: unset;
    }

    .flex {
      display: flex;
      align-items: center;
    }
  }
}
