// NFON
// Primary
@font-face {
  font-family: "PPPangramSans";
  font-weight: 300;
  src: url("../../assets/fonts/PPPangramSans-Light.otf") format("opentype");
}

@font-face {
  font-family: "PPPangramSans";
  font-weight: 400;
  src: url("../../assets/fonts/PPPangramSans-Regular.otf") format("opentype");
}

@font-face {
  font-family: "PPPangramSans";
  font-weight: 500;
  src: url("../../assets/fonts/PPPangramSans-Medium.otf") format("opentype");
}

@font-face {
  font-family: "PPPangramSans";
  font-weight: 700;
  src: url("../../assets/fonts/PPPangramSans-Bold.otf") format("opentype");
}

// Secondary
@font-face {
  font-family: "Nunito";
  font-weight: 400;
  src: url("../../assets/fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito";
  font-weight: 700;
  src: url("../../assets/fonts/Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito";
  font-weight: 500;
  src: url("../../assets/fonts/Nunito-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito";
  font-weight: 300;
  src: url("../../assets/fonts/Nunito-Light.ttf") format("truetype");
}

// DTS
@font-face {
  font-family: "FiraSans";
  font-weight: 300;
  src: url("../../assets/fonts/FiraSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "FiraSans";
  font-weight: 400;
  src: url("../../assets/fonts/FiraSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "FiraSans";
  font-weight: 500;
  src: url("../../assets/fonts/FiraSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "FiraSans";
  font-weight: 700;
  src: url("../../assets/fonts/FiraSans-Bold.ttf") format("truetype");
}

