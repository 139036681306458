@import '../../../sass/base/legal_entities';
@import '../../../sass/base/colors';
@import '../../../sass/base/typography';
@import '../../../sass/base/common';

.commissionTable {
  @include box;
  @include card;

  .empty-state {
    padding: 2rem;
  }

  td:nth-child(4) {
    width: 22%;
  }

  .downloadTable-title {
    margin-top: 0;
  }

  .paginatedTable {
    padding: 0;
  }
}