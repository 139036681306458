@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/dimensions";
@import "src/portal/sass/base/typography";
@import "src/portal/sass/base/common";
@import "src/portal/sass/base/legal_entities";

.overview {
  @include box;
  display: flex;

  &-le {
    @include legalEntityComponentSecondaryStyling();
  }

  .moreLink-le {
    @include legalEntityComponentInvertedLinkStyling();
    @include legalEntityContrastLinkColor();
  }

  .figureCaption-le {
    @include legalEntityCustomerDataLabelStyling();
  }

  .figureCaption-le--dts {
    color: $color_darkGrey !important;
  }

  .content-le {
    svg {
      g, path {
        fill: var(--text-color);
      }
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;
    margin-bottom: $height_mediumSpace;
  }

  .column {
    flex-basis: 25%;
    margin-bottom: 1rem;
  }

  h4,
  p {
    flex-basis: 100%;
  }

  section {
    flex-basis: 33.333%;
    margin-top: $height_bigSpace;

    .figureCaption {
      @include hasIcon;

      &-icons-le {
        @include legalEntityIconsPrimaryFontColorStyling();
      }
    }
  }

  &-moreLink {
    margin-top: 3.5rem;
  }
}
