@import 'src/portal/sass/base/legal_entities';
@import 'src/portal/sass/base/colors';

ul {
  .firstLine {
    display: flex;
    justify-content: space-between;

    &-le {
      @include legalEntityActiveFontColor();
      @include legalEntityActiveBackgroundColor();
    }

   .pointer {
      cursor: pointer;
    }

    .iconDefault {
      &-le {
        @include legalEntityActiveFontColor();
      }
    }

    a {
      display: flex;
      justify-content: space-between;
      padding-left: 16px;
    }

    .pointer {
      align-items: flex-start;
      svg {
        margin-right: 16px !important;
      }
    }
  }

  a {
    padding-left: 52px;
  }

  div.firstLine > li {
    padding-left: 0px;
  }
}

.partnerCustomerSubmenu {
  &-container-le {
    @include legalEntityNavigationSeperatorStyling();
  }

  &-customerTitle {
    overflow-wrap: anywhere;
    font: var(--typo-h5);
  }

}