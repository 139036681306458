@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/legal_entities";
@import "src/portal/sass/base/dimensions";

.sitesTable {
  @include box;
  @include card;

  .status {
    @include hasIcon;
  }

  td {
    &:nth-child(1) {
      overflow-wrap: anywhere;
      width: 20%;
    }

    &:nth-child(2) {
      overflow-wrap: anywhere;
      width: 21%;
    }

    &:nth-child(3) {
      width: 10% !important;
    }

    &:nth-child(4) {
      width: 15% !important;
    }

    &:nth-child(5) {
      width: 15% !important;
    }

    &:nth-child(6) {
      width: 19%;
    }
  }

}