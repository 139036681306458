@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/legal_entities';


.modalInfoText {
  margin-top: 16px;
  margin-bottom: 2rem;
}

.terminationRequested {
  color: $color_warning;
}

.modalBoxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em 1em;
  margin-top: 32px;
  margin-bottom: 15px;
}

.errorField {
  margin-bottom: 64px;
  color: red;
}
