@import "src/portal/sass/base/dimensions";
@import "src/portal/sass/base/typography";
@import "src/portal/sass/base/legal_entities";
@import "src/portal/sass/base/themes";

.footer {
  display: flex;
  max-width: $width_gridFull;
  margin: $height_footerMargin auto;
  justify-content: space-between;
  font-size: 0.875rem;

  &-le {
    @include legalEntityFooterStyle();
  }

  &-legal-notice {
    display: flex;
    gap: 1rem;
  }

}

.bottom-spacing {
  margin-bottom: calc($height_footerMargin + $height_headerTop);
}
