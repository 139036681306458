@import "../../../../../../portal/sass/base/colors";

.subText {
  margin-bottom: 0 !important;
}

.modal {
  &-dialog-le--dts {
    .dropdown {
      .select {
        color: $color_primary_dts !important;
        background: inherit;

        option {
          background: white !important;
        }
      }
    }
  }
}