@import '../../../sass/base/colors';
@import '../../../sass/base/legal_entities';

.extensions-headerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-title-le {
    @include legalEntityPageTitleStyling();
  }

  h2 {
    width: 300px;
  }

  .buttonIcons {
    gap: .5rem;
  }

  .infoBox {
    display: flex;
    align-items: center;

    button {
      margin-left: 24px;
    }
  }
}
