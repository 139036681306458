@import './portal/sass/base/legal_entities.scss';

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &-le {
    height: 100%;
    @include legalEntityPageStyling();
  }

  &-le--dts {
    a {
      font-weight: 500;
    }
  }
}

.main {
  margin-top: 1rem;
  height: 100%;
}

.loginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 20rem;
  animation: appear 0.6s ease-in-out forwards;
  width: 100%;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.iconClass {
  &-le {
    @include legalEntityIconPathStyling();
  }
}

.statusIconClass {
  &-le {
    @include legalEntityStatusIconPathStyling();
  }
}

.page-holder {
  position: relative;
  height: 100vh;

  .page {
    //margin: $page-margin 0;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
  }
}

.my-node-enter {
  opacity: 0;
}

.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.my-node-exit {
  opacity: 1;
}

.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
