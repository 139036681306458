@use "sass:math";
@import "../../portal/sass/base/dimensions";
@import "../../portal/sass/base/colors";
@import "../../portal/sass/base/typography";
@import "../../portal/sass/base/common";
@import "../../portal/sass/base/legal_entities";


.helpBox {
  position: relative;

  &-icon {
    cursor: help;
    &-le {
      @include legalEntityHelpBoxIconStyling();
    }
  }

  .popup {
    position: absolute;
    z-index: 100;
    bottom: $height_helpBoxBottom;
    right: -0.5rem;

    min-width: $width_helpBoxMin;
    max-height: $height_helpBoxMax;
    padding: $height_helpBoxPadding $width_helpBoxPadding;
    border: solid 1px rgba(174, 198, 211, 0.25);

    p {
      font-size: $fontsize_small !important;
    }

    &-le {
      @include legalEntityHelpBoxStyling();
      @include legalEntityBoxShadow();
    }

    p:not(:last-child) {
      margin-bottom: $height_smallSpace;
    }
  }

  .turn-right {
    left: -0.5rem;

    &::before {
      left: 0.75rem;
    }
  }

  .turn-left {
    right: -0.5rem;

    &::before {
      right: 0.75rem;
    }
  }


}
