@import "../../../../sass/base/legal_entities";

.selectQuantityModal {

  display: flex;
  justify-content: flex-start;
  gap: 3rem;

  &-hr {
    margin: 1.7em 0;

    &-le {
      @include legalEntityLineColorDark();
    }
  }

  .textWithLabel {
    .text .line-through {
      display: initial !important;
      font-size: .85rem;
    }

    p {
      display: initial;
    }

  }
}

.discountInfo + .selectQuantityModal {
  margin-top: 2rem;
}