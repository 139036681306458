@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/dimensions";
@import "src/portal/sass/base/legal_entities";

.miniBasket {
  @include box;
  position: relative;
  background-color: black;

  &-le {
    @include legalEntityNavigationStyling();
    @include legalEntitySmallFontSize();
  }

  &-heading {
    margin-bottom: 1rem;
  }

  .table {
    &-le {
      @each $le in $legal_entities {
        &--#{$le} {
          color: getLegalEntityTableChildFontColor($le);

          tr {
            &.product {
              font-size: 16px;
            }

            &.second-hierarchy {
              border-left: 0.5em solid transparent;
            }

            &.third-hierarchy {
              border-left: 1.5em solid transparent;
            }

            &.sum {
              td {
                font-size: 16px;
              }

              td:last-child {
                text-align: right;
                font-size: 20px;
              }
              &:last-child {
                td {
                  border: 0;
                }
              }
            }

            td {
              &.product-name {
                border-top: none;
                border-bottom: none;
                display: flex;

                span {
                  margin: -5px 0 10px 0;
                }
              }

              &.product-name-bold {
                color: getLegalEntityActiveFontColor($le);
                font-weight: 1000;
              }

              &.product-price {
                border-top: none;
                border-bottom: none;
                font-weight: 500;
                text-align: right;

                .product-interval {
                  font-size: 14px;
                  color: #969696;
                }
              }

              &.product-action {
                &-le {
                  @include legalEntityStatusIconPathStyling();
                }
              }

              &:nth-child(2) {
                width: 30%;
              }
            }
          }

          .sum-one-time td {
            padding-top: 1rem;
          }
          .divider {
            border-bottom: 1px solid getLegalEntityMonthlyTableLines($le);
          }


          td,
          tr:first-child td {
            border: 0;
          }

          border-collapse: collapse;
          border-spacing: 0;

          tr {
            td {
              padding: 0 0 $dimension_cellSpacingSmall;
              font-size: 16px;

              svg {
                min-width: 18px;
              }
            }
          }

        }
      }
    }
  }
}
