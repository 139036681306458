@import '../../portal/sass/base/common';
@import '../../portal/sass/base/dimensions';
@import '../../portal/sass/base/colors';
@import '../../portal/sass/base/legal_entities';

.pagination {
  margin: 0 auto;
  position: relative;
  width: max-content;
  max-width: $width_paginationMax;
  display: flex;
  flex-wrap: wrap;

  .pageUp,
  .pageDown {
    position: absolute;
    top: 0;
  }

  .pageDown {
    left: 0;
    transform: translateX(-100%);
  }

  .pageUp {
    right: 0;
    transform: translateX(100%);
  }

  .button {
    @include buttonInDisguise;
    line-height: 1.2;
    padding: 0 $width_tableButtonsPadding;
    cursor: pointer;
    font-weight: 300;

    &-le {
      @include legalEntityPaginationColor();
      @include legalEntityBodyFontSize();
    }

    &.active {
      font-weight: 500;

      &-le {
        @include legalEntityPaginationActiveColor();
      }
    }
  }
}

.sorting_arrows_wrapper {
  display: flex;
  gap: .5rem;
}