@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/typography';
@import 'src/portal/sass/base/legal_entities';

.telephoneConnection {
  @include box;

  &-le {
    @include legalEntityComponentStyling();
  }

  .telephoneConnectionHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-le {
      @include legalEntityComponentBoxHeaderIconStyling();
    }
  }

  table {
    .boxHeader {
      &-le {
        @include legalEntityBoxHeaderStyling();
      }
    }

    tbody {
      td.line-le {
        padding-bottom: calc(#{$dimension_cellSpacing} / 2);
        @include legalEntityLastTableLinesStyling();
      }
    }

    th {
      width: 121px;
      height: 24px;
      margin: 0 97px 12px 0;
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      padding-top: unset;
    }

    .startdate, .enddate {
      @include hasIcon;
      border-top: unset;
    }

    td {
      @include hasIcon;
      border-top: unset;

      &:nth-child(1) {
        width: $width_contractTable_col1;
      }

      &:nth-child(2) {
        width: $width_contractTable_col2;
      }

      &:nth-child(3) {
        width: $width_contractTable_col3;
      }

      &:nth-child(4) {
        width: $width_contractTable_col4;
      }
    }

    .startdate,
    .enddate,
    .subContract {
      white-space: nowrap;
    }
  }

  tbody {
    tr:first-child {
      &.telephoneConnection-firstRow-le {
        @include legalEntityFirstTableLinesStyling();
      }
    }
  }
}
