@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.profileBox {
  &-title-le {
    @include legalEntityPageTitleStyling();
  }
}

.myProfileBox {
  @include box;

  &-le {
    @include legalEntityComponentStyling();
  }

  input, .dropdown {
    max-width: $width_inputMax;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: $height_bigSpace;
  }

  &-subtitle-le {
    @include legalEntityCustomerDataLabelStyling();
  }

  &-actions {
    margin-top: 2rem;
    display: flex;
    justify-content: right;
    gap: 1rem;
  }

  #areaCode {
    width: 110px;
  }

  #countryCode {
    width: 100px;
  }

  &-username-wrapper {
    input {
      display: inline-flex;
    }
  }
}
