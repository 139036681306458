@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/legal_entities';

.sipTrunkTable {
  @include box;
  @include card;

  .status {
    @include hasIcon;
  }

  .sipTrunk {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .title {
      margin-bottom: .5rem;
    }
  }

  table {

    tbody {
      td.line-le {
        padding-bottom: calc(#{$dimension_cellSpacing} / 2);
        @include legalEntityLastTableLinesStyling();
      }
    }

    th {
      width: 121px;
      height: 24px;
      margin: 0 97px 12px 0;
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      padding-top: unset;
    }

    .startdate, .enddate {
      @include hasIcon;
      border-top: unset;
    }

    th {
      @include hasIcon;
      border-top: unset;

      &:nth-child(1) {
        width: 40% !important;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 30% !important;
      }

      &:nth-child(4) {
        width: 20% !important;
      }
    }

    .flex {
      display: flex;
      align-items: center;
    }
  }
}
