@import '../../portal/sass/base/colors';
@import '../../portal/sass/base/legal_entities';
@import '../../portal/sass/base/typography';
@import '../../portal/sass/base/common';

.selectBox {
  position: relative;
  padding: 16px;
  min-height: 185px;
  cursor: pointer;
  @include card;

  border: var(--input-border);

  &__text-wrapper {
    padding-bottom: .5rem;
    border-bottom: 1px solid #666666;
    margin-bottom: 1rem;
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    gap: .5rem;

    .helpBox {
      &-le {
        @include legalEntityStatusIconPathStyling();
      }

      g {
        fill: white !important;
      }
      .popup {
        min-width: 200px;
        bottom: 5rem;
        right: .5rem;
      }
    }
  }

  &__title {
    margin-bottom: .5rem;
  }

  &__dependency-text {
    font: var(--typo-body-small);
  }

  &.active-le {
    @include legalEntitySelectBoxActive();
  }

  &.large {
    min-height: 168px;
    grid-column: 1/3;
    cursor: unset;

    .selectBox_header {
      display: flex;
      justify-content: space-between;

      padding-bottom: .5rem;
      border-bottom: 1px solid #666666;
      margin-bottom: 1rem;
    }

    .selectBox_header__title {
      margin: 0;
    }

    .selectBox_header__actions {
      z-index: 100;
      display: flex;
      gap: 1rem;

      button {
        pointer-events: initial;
      }

      svg {
        height: 2rem;
        width: 1.5rem;
      }

      &-icon {
        &-le {
          @include legalEntityIconsPrimaryStyling();
        }
      }
    }

    .selectBox_sites {
      &__item {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
      }
    }


  }

  &.disabled {
    cursor: default;

    &-le {
      @include legalEntityDisabledSelectBoxBackground();
    }

    .selectBox__title,
    .selectBox-children,
    .selectFooter {
      opacity: .5;
    }

    .selectText {
      filter: saturate(0);
    }

  }

  .innerBox {

    .current {
      display: flex;
      gap: 5px;
      font-size: 14px;
      align-items: center;
    }

    .selectBox-children {
      margin-bottom: 43px;

      .charges {
        font-weight: bold;
      }
    }

    .selectText {
      position: absolute;
      font-size: $fontsize_small;
      text-transform: uppercase;
      right: 2rem;
      bottom: 1rem;

      color: var(--primary-color);
      text-transform: var(--button-text-transform);
      font: var(--typo-button);

      &-le--dts {
        font-weight: 500;
      }
    }
  }

  .selectFooter {
    display: flex;
    position: absolute;
    align-items: center;
    bottom: 0;
    justify-content: space-between;

    .inputWithLabel {
      width: 22%
    }

    .selectText {
      position: initial;
      padding: 1rem 1rem 0 0;
    }
  }
}
