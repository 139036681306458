@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/dimensions";
@import "src/portal/sass/base/legal_entities";

.connection {
  @include box;
  padding-bottom: $height_bigSpace;

  &-le {
    @include legalEntityComponentStyling();
  }

  h3 {
    margin-bottom: $height_mediumSpace;
  }

  &-componentTitle-le {
    @include legalEntityCustomerDataLabelStyling();
  }

  &-inputLabel-le {
    @include legalEntityInputLabelFontStyling();
  }

  h5 {
    max-width: $width_inputMax;
    margin-bottom: 22px;

    .helpBox {
      float: right;
    }
  }

  .limitInput {
    display: inline-block;
    margin-bottom: 0;

    input {
      width: $width_inputMax;
    }
  }

  .fraudDetection {
    margin-top: $height_bigSpace;
  }

  .warning {
    &-le {
      @include legalEntityInlineErrorStyling();
    }

    p {
      position: relative;
      left: 2.1rem;
    }

    .formColumn:first-child {
      max-width: $width_inputMax;
    }
  }

  .success {
    &-le {
      @include legalEntityInlineSuccessStyling();
    }

    p {
      position: relative;
      left: 2.1rem;
    }

    .formColumn:first-child {
      max-width: $width_inputMax;
    }
  }

  form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: $height_mediumSpace;

    &:last-child {
      padding-bottom: 0;
    }
  }

  h5 {
    @include hasIcon;
  }

  .columnContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .small {
    font-size: $fontsize_small;
    color: $color_smallFont;
  }

  .progressBar {
    margin-top: $height_miniSpace;
  }

  .progressBarWrapper {
    max-width: $width_inputMax;
  }

  .fraudDetection {

    .switch-text {
      &-le {
        @include legalEntitySwitchWithTextStyling();

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 120px;
          height: $height_switch_text;
          box-sizing: border-box;
          border-radius: 100px;
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 68px;
          height: $height_switch_text;
          transition: all 0.2s ease-in-out;
          border-radius: 100px;
        }

        &.active {
          &::after {
            width: 58px;
            left: 120px - 58px;
          }
        }

        &.en {
          width: 140px;

          &::before {
            width: 130px;
          }

          &::after {
            width: 72px;
            transition: all 0.2s ease-in-out;
          }

          &.active {
            &::after {
              width: 62px;
              left: 130px - 62px;
            }
          }
        }
      }
    }

    .switch-text-left,
    .switch-text-right {

      &-le {
        @include legalEntitySwitchWithTextParagraphStyling();
      }
    }

    .switch-text-left {
      left: .8rem;
    }

    .switch-text-right {
      right: 1.5rem;

      &.big {
        right: .6rem;
      }

      &.en {
        right: 1.275rem !important;
      }
    }


  }

}
