@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/legal_entities';

.authForm {
  padding: $height_marginDefault $width_marginDefault;

  &-le {
    @include legalEntityComponentInvertedPrimaryStyling();
  }

  &-label-le {
    @include legalEntityLoginLabelStyling();
  }

  &-input-le {
    @include legalEntityLoginInputStyling();
  }

  h3 {

    &.authForm-loginTitle-le {
      @include legalEntityPageTitleStyling();
    }
  }

  .title {
    &-le {
      @include legalEntityPageTitleStyling();
    }
  }

  .context {
    margin-bottom: 32px !important;
    margin-top: 1rem !important;

    &-le {
      @include legalEntityPageContextStyling();
    }
  }

  h5 {
    margin-top: $height_bigSpace;
    margin-bottom: $height_mediumSpace;

    &.authForm-loginSubTitle-le {
      @include legalEntityPageSubTitleStyling();
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;

    & > *:first-child {
      font-size: $fontsize_small;
    }

    & > *:last-child {
      margin-left: auto;
    }
  }

  .error {
    &-le {
      @include legalEntityErrorMessageStyling();
    }

    .error-text {
      font-size: $fontsize_medium;
    }
  }
}
