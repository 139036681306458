@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.productOptionsBox {
  padding: 24px;
  margin-bottom: 1rem;

  &-le {
    @include legalEntityNavigationStyling();
  }

  .boxes {
    margin-bottom: 0;
  }

  &__heading {
    margin-bottom: 1rem;
  }

  &__actions {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    :first-child {
      margin-right: 1rem;
    }

    p {
      font-size: 14px;
    }
  }
}