/* Standard Colors */
$color_white: #fff;
$color_black: #000;
$color_darkGrey: #323232;
$color_lightGrey: #f3f6f8;
$color_blueGrey: #6298b3;
$color_lightBlueGrey: #9ab7c1;

/* States */
$color_error: #e22800;
$color_success: #10bc00;
$color_warning: #f99505;
$color_neutral: #aec6d3;

/* Legal Entity Default Colors */
// NFON
$color_primary_nfon: #3200CB;
$color_secondary_nfon: #00FF91;
$color_white_nfon: $color_white;
$color_bg_grey_nfon: #303030;
$color_box_border_nfon: #666666;
$color_input_bg_nfon: #4A4A4A;
$color_input_border_nfon: #666666;
$color_input_color_nfon: $color_white;
$color_button_hover_nfon: #52ffb4;
$color_button_active_nfon: #00D67A;
$color_error_nfon: #ff0000;


// DTS
$color_primary_dts: #004d7e;
$color_yellow_dts: #ffc600;
$color_bg_beige_dts: #f0eee4;

/* Page Backgrounds */
$color_pageBackground_nfon: $color_bg_grey_nfon;
$color_pageBackground_dts: $color_bg_beige_dts;

/* Fonts */
$color_font_nfon: $color_white_nfon;
$color_font_highlight_dts: $color_primary_dts;
$color_font_dark_dts: $color_darkGrey;
$color_fontInactive: $color_neutral;

/* Buttons */
$color_primaryHover_nfon: #009de0;
$color_primaryHover_dts: #3670a0;
$color_primaryDisabled: mix($color_white, $color_primary_nfon, 60%);
$color_primaryDisabled_dts: rgba(0, 77, 126, 0.25);
$color_button_secondary_nfon: $color_primary_nfon;
$color_button_secondary_dts: $color_primary_dts;
$color_secondaryHover_nfon: $color_primaryHover_nfon;
$color_secondaryHover_dts: $color_primaryHover_dts;
$color_secondaryDisabled: mix($color_white, $color_white_nfon, 60%);
$color_switchHover_nfon: #6298b3;
$color_switchHover_dts: $color_neutral;
$color_switchHover_outerHover_dts: $color_lightBlueGrey;
$color_switchOff_nfon: #969696;


/* Todo: Sort */
$color_component_background_dts: $color_font_highlight_dts;
$color_errorHover: #d12600;
$color_actionDefault: $color_primary_nfon;
$color_inputBorder: $color_lightBlueGrey;
$color_separator: #dedede;
$color_boxShadow: rgba(195, 195, 195, 0.5);
$color_sumLine: $color_blueGrey;
$color_smallFont: $color_blueGrey;
$color_tableLines: #8ec5e0;
$color_tableLineTop: $color_blueGrey;
$color_activeTableRow: $color_bg_grey_nfon;
$color_tableFirstChild_nfon: $color_white_nfon;
$color_tableFirstChild_dts: $color_primary_dts;
$color_tableSecondToLastChild_nfon: $color_white_nfon;
$color_tableSecondToLastChild_dts: $color_darkGrey;

$color_tableFirstLine_nfon: $color_white_nfon;
$color_tableLines_nfon: #aec6d3;

$color_tableFirstLine_dts: #555555;
$color_tableLines_dts: $color_tableFirstLine_dts;

$color_progressBarBackground: mix($color_white, $color_sumLine, 68%);
$color_progressBarBackground_nfon: $color_box_border_nfon;
$color_progressBarBackground_dts: $color_neutral;

$color_boxBorder: #b0cad5;
$color_boxBorder_dts: #555555;
$color_avatar: #4cc8f1;
$color_avatarBG_Nfon: rgba(255, 255, 255, 0.3);
$color_navItemBackgroundHover: mix($color_white, #99c1d5, 85%);
$color_loadingSpinner1: $color_input_bg_nfon;
$color_loadingSpinner2: rgb(195, 195, 195);
$color_passwordStrengthIndicator: #eefafd;
$color_toastBoxShadow: rgba(0, 0, 0, 0.19);

$color_lineColor_nfon: #b0cad5;
$color_lineColor_dts: #e0e9ec;
$color_lineColor_dark_dts: #555;
