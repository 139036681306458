@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/dimensions";
@import "src/portal/sass/base/legal_entities";

.updateContactData {
  @include box;
  @include card;
  
  .componentTitle {
    &.componentTitle-le {
      @include legalEntityCustomerDataLabelStyling();
    }
  }

  .actions {
    display: flex;
    justify-content: end;
  }
}