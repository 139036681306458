@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.activation-date {
  margin-bottom: 32px;

  &-le {
    @include legalEntityNavigationStyling();
  }

  &-title {
    margin-bottom: 1rem;

    &-le {
      @include legalEntityHeadingStylingH4();
    }
  }

  &-content {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    height: 40px;

    &--text {
      flex-shrink: 0;
    }

    .dateInputWithLabel {
      margin-bottom: 0;
      width: 100%;
    }
  }

  &.orderEntry {
    padding: 24px 24px 32px 24px;
  }

  &:not(.orderEntry) {
    margin-top: 2rem;
  }

  &.basketView {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $color_box_border_nfon;
  }
}