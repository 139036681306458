@import "../../portal/sass/base/dimensions";
@import "../../portal/sass/base/colors";
@import "../../portal/sass/base/legal_entities";

.switchWrapper {
  display: block;

  input {
    display: none;
  }

  .switch {
    display: block;
    width: $width_switch;
    height: $height_switch;
    position: relative;
    cursor: pointer;

    &-le {
      @include legalEntitySwitchStyling();

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: $width_switch;
        height: $height_switch;
        box-sizing: border-box;
        border-radius: calc(#{$height_switch} / 2);
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: $height_switch;
        height: $height_switch;
        transition: left 0.2s linear;
        border-radius: calc(#{$height_switch} / 2);
      }

      &.on {
        &::after {
          left: $width_switch - $height_switch;
        }
      }
    }

    &-le--nfon {
      &.on &::before {
        &::before {
          opacity: 0.4 !important;
        }
      }
    }
  }
}
