@import "../../portal/sass/base/colors";
@import "../../portal/sass/base/dimensions";
@import "../../portal/sass/base/typography";

.checkboxGroup {
  border: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: $height_smallSpace;

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      padding: 0;
      display: flex;
      gap: 1rem;
      align-items: start;

      label {
        font-size: $fontsize_medium;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
}
