@import "./colors";
@import "./dimensions";

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@mixin hasSpinner {
  &::after {
    content: "";
    z-index: 99;
    top: 50%;
    left: 50%;
    border-radius: 1000rem;
    width: 20px;
    height: 20px;
    position: absolute;
    border-top: 0.2em solid $color_loadingSpinner2;
    border-right: 0.2em solid $color_loadingSpinner2;
    border-bottom: 0.2em solid $color_loadingSpinner2;
    border-left: 0.2em solid $color_loadingSpinner1;
    transform: translate(50%, 50%);
    animation: load8 1.1s infinite linear;
    opacity: 1;
  }
}
