@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/legal_entities";

.customerSipTrunkTable {
  @include box;

  &-le {
    @include legalEntityComponentStyling();
  }

  .status {
    @include hasIcon;
  }

  th {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      text-align: center;
    }
  }

  td {
    @include hasIcon;

    &:nth-child(1) {
      width: 8% !important;
      padding-right: 0 !important;
    }

    &:nth-child(2) {
      width: 25% !important;
      text-align: left;
    }

    &:nth-child(3) {
      width: 15% !important;
      text-align: center;
    }

    &:nth-child(4) {
      width: 10% !important;
      text-align: center;
    }

    &:nth-child(5) {
      width: 10% !important;
      text-align: center;
    }

    &:nth-child(6) {
      width: 3% !important;
    }

    &:nth-child(7) {
      width: 3% !important;
      text-align: right;
    }
  }
}

.tableFirstChild {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #00b1eb;

  &-font-le {
    @include legalEntityTableFirstChildFontColor();
    @include legalEntityBodyFontSize();
  }

  &.txt-record {
    padding: 0 !important;
  }
}

.successor {
  display: flex;

  svg {
    min-width: 24px;
    margin-right: 0.5em;
  }
}
