@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/legal_entities";

.myDataOverview {
  @include box;
  color: var(--text-color);


  &-boxStyling-le {
    @include legalEntityComponentSecondaryStyling();
    @include legalEntityIconsPrimaryFontColorStyling();
  }

  .subheading {
    margin-bottom: $height_mediumSpace;
  }

  .columnContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .column {
      flex-basis: calc(50% - (#{$width_marginDefault} / 2));

      &:first-child {
        .logo svg {
          height: 216px;
        }
      }

      .column-title {
        display: flex;
        align-items: center;
        gap: .5rem;
      }

      .column-title-le--dts {
        color: $color_darkGrey !important;
      }

      .email {
        overflow-wrap: anywhere;
      }
    }
  }

  h4 {
    @include hasIcon;
    color: var(--text-color) !important;

    &:not(:first-child) {
      margin-top: $height_mediumSpace;
    }
  }
}
