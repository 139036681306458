@import 'src/portal/sass/base/colors';

.alert {
  background-color: $color-error;
  color: white;
  margin: 1rem 0;
  padding: 16px 12px 16px 16px;

  .entry {
    display: flex;
    gap: 1rem;

    svg {
      align-items: center;
      align-self: center;
      width: 26px;
      height: 26px;
    }

    .messages {
      font-size: 15px;
    }
  }
}
