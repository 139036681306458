@import "../../../sass/base/legal_entities";

.sipTrunk {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &-title-le {
    @include legalEntityPageTitleStyling();
  }

  &-subtitle-le {
    @include legalEntityPageSubtitleStyling();
  }

  .buttonIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    border-radius: 2px;
    padding-left: 6px;

    p {
      margin-left: 8px;
    }
  }

  &-voiceChannelModal {
    .modalBoxes {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1em 1em;
      margin-top: 32px;
      margin-bottom: 15px;
    }

    .activation-date {
      padding: 1rem 0 0 0;
    }

    .usedExtensionsLegend {
      margin-left: 8px;
    }

    .freeExtensionsLegend {
      margin-left: 0 !important;
    }
  }
}
