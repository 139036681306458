@import "../../portal/sass/base/colors";
@import "../../portal/sass/base/dimensions";
@import 'src/portal/sass/base/legal_entities';

.checkboxWrapper {
  position: relative;
}

.checkboxWithIndeterminate {
  margin: 0;
  left: 0;
  top: $height_checkboxOffset;
  -moz-appearance: initial;
  width: $dimension_checkbox;
  height: $dimension_checkbox;


  &::after {
    display: block;
    position: absolute;
    content: "";
    box-sizing: border-box;
    width: $dimension_checkbox;
    height: $dimension_checkbox;
    top: 0;
    left: 0;
    background: $color_bg_grey_nfon;
    border: 1px solid $color_box_border_nfon;
    cursor: pointer;
    text-align: center;
    line-height: 1;
    transition: all ease-in-out 0.2s;


  }

  &::before {
    content: "";
    position: absolute;
    border-bottom: $dimension_checkboxCheckmarkThickness solid $color_black;
    border-left: $dimension_checkboxCheckmarkThickness solid $color_black;
    width: $dimension_checkbox * 0.45;
    height: $dimension_checkbox * 0.15;
    z-index: 10;
    top: $dimension_checkbox * 0.35;
    left: $dimension_checkbox * 0.26;
    transform: rotate(-45deg);
    opacity: 0;
    cursor: pointer;
  }

  &:checked::before {
    opacity: 1;
  }

  &:checked::after {
    opacity: 1;
    background: $color_secondary_nfon;
  }

  &:indeterminate::before {
    opacity: 1;
    height: 0;
    border-left: 0;
    transform: none;
    top: calc(#{($dimension_checkbox * 0.5)} - #{$dimension_checkboxThickness});
  }

  &:indeterminate::after {
    background: $color_secondary_nfon;
  }
}

.checkboxWithIndeterminateDTS {
  margin: 0;
  left: 0;
  top: $height_checkboxOffset;
  -moz-appearance: initial;
  width: $dimension_checkbox;
  height: $dimension_checkbox;


  &::after {
    display: block;
    position: absolute;
    content: "";
    border: $dimension_checkboxThickness solid $color_darkGrey;
    box-sizing: border-box;
    width: $dimension_checkbox;
    height: $dimension_checkbox;
    top: 0;
    left: 0;
    background: $color_white;
    border-radius: $dimension_checkboxBorderRadius;
    cursor: pointer;
    text-align: center;
    line-height: 1;
    transition: all ease-in-out 0.2s;
  }

  &::before {
    content: "";
    position: absolute;
    border-bottom: $dimension_checkboxCheckmarkThickness solid $color_font_nfon;
    border-left: $dimension_checkboxCheckmarkThickness solid $color_font_nfon;
    width: $dimension_checkbox * 0.45;
    height: $dimension_checkbox * 0.15;
    z-index: 10;
    top: $dimension_checkbox * 0.35;
    left: $dimension_checkbox * 0.26;
    transform: rotate(-45deg);
    opacity: 0;
    cursor: pointer;
  }

  &:checked::before {
    opacity: 1;
    border-bottom: $dimension_checkboxCheckmarkThickness solid $color_white;
    border-left: $dimension_checkboxCheckmarkThickness solid $color_white;
  }

  &:checked::after {
    opacity: 1;
    background: $color_primary_dts;
  }

  &:indeterminate::before {
    opacity: 1;
    height: 0;
    border-left: 0;
    transform: none;
    top: calc(#{($dimension_checkbox * 0.5)} - #{$dimension_checkboxThickness});
  }
}
