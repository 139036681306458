@import "src/portal/sass/base/common";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/legal_entities";

.figureBox {
  @include box;
  display: flex;
  flex-direction: column;

  &-le {
    @include legalEntityFigureBoxStyling();
  }

  .moreLink {
    &-le {
      align-self: flex-end;
      @include legalEntityComponentInvertedLinkStyling();
    }

    &-le--nfon {
      color: $color_secondary_nfon !important;
    }
  }
}
