@import "src/portal/sass/base/dimensions";

.downloads {
  display: flex;
  & > * {
    width: 50%;
    margin-right: $dimension_boxSpace;
    &:last-child {
      margin-right: 0;
    }
  }
}
