@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/common";
@import "src/portal/sass/base/dimensions";
@import "src/portal/sass/base/typography";
@import "src/portal/sass/base/legal_entities";

@mixin avatar($height) {
  border-radius: calc(#{$height} / 2);
  width: $height;
  height: $height;
  &-le {
    @include legalEntityAvatarStyling();
  }
  border: 0;
  padding: 0;
  margin: 0;
  line-height: 1; //$height;
  text-align: center;
  font-size: calc(#{$height} / 2.5);
  overflow: hidden;
  svg {
    width: 50%;
  }
}

.userNavigation {
  position: relative;

  & > button {
    @include avatar(calc(#{$dimension_headerUtilities}));

    &.userNavigation-avatar-le {
      @include legalEntityAvatarStyling();
    }

    cursor: pointer;
  }

  .userNavigation-popup {
    @include popup;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.2s ease;

    &.active {
      transform: scaleY(1);
      transition: transform 0.5s ease;
    }

    &-le {
      @include legalEntityUserNavigationStyling();
      @include legalEntityBoxShadow();
    }

    h3,
    p,
    a {
      white-space: nowrap;
    }

    .userNavigation-header {
      display: flex;
      justify-content: stretch;
      padding: $dimension_popupPadding 0;
      margin: 0 $dimension_popupPadding;

      &-le {
        @include legalEntityMenuLineStyling();
      }

      .userNavigation-avatar {
        @include avatar($dimension_avatar);
        margin-right: $width_iconSpace;

        svg {
          margin-top: 20%;
        }
      }

      h3 {
        font-size: $fontsize_body;
        line-height: 1;
        font-weight: 500;
      }

      p {
        font-size: $fontsize_small;
        line-height: 1;
      }

      .userNavigation-userInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    ul,
    li {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    nav {
      margin: $dimension_popupPadding 0;

      button {
        @include buttonInDisguise;
        text-align: left;
        cursor: pointer;
      }

      a {
        font-weight: normal;
        display: block;
        width: 100%;
        padding: $height_popupNavigationItemsPadding $dimension_popupPadding;
        cursor: pointer;

        &:hover {
          background: $color_navItemBackgroundHover;
        }

        box-sizing: border-box;
      }

      a {
        text-decoration: none;
      }
    }
  }
}
