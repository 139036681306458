@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.stepper-footer {

  &-le {
    @include legalEntityStepperFooterStyling();
  }

  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 84px;
  align-items: center;
  z-index: 100;

  &-content {
    display: flex;
    justify-content: space-between;
    width: $width_gridFull;
    margin: 0 auto;

    &--right {
      display: flex;
      gap: 1.5rem;
    }
  }
}

