@import "src/portal/sass/base/common";

.inboundOutbound {
  display: flex;
  justify-content: space-between;
  & > * {
    @include hasIcon;
    svg {
      margin-right: 0;
    }
  }

  div {
    display: flex;
    gap: .5rem;
  }

}
