@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.porting-numbers-modal {

  .modalTitle {
    margin-bottom: 1.5rem;
  }

  .twoButton {
    margin-top: 2rem;
  }

  .inputInline {
    display: inline-block;
  }

  &-subheading {
    margin-bottom: 1rem;
  }

  .subheading {
    padding-bottom: 2rem;
  }

  &-section-container {
    padding-top: 2rem;

    &.callNumbers {
      border-bottom: 1px solid $color_box_border_nfon;
      border-top: 1px solid $color_box_border_nfon;
      padding-bottom: 3rem;
    }
  }

  &-switch-container {
    margin-bottom: 1rem;
  }

  &-checkbox {
    margin-bottom: 3rem;
  }

}