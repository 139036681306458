@import "../../../../sass/base/legal_entities";
@import "../../../../sass/base/common";

.editSipTrunk {
  .selectBox {
    &__text-wrapper {
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  &-currentVoiceChannel {
    margin: 2rem 0;
    padding: 1rem;

    &-le {
      @include legalEntitySelectBoxActive();
    }

    .current {
      display: flex;
      gap: 5px;
      font-size: 14px;
      align-items: center;
      margin-bottom: 1rem;
    }

    h4 {
      margin-bottom: .5rem;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }

}

.sub-text {
  margin-bottom: 0 !important;
}

.sub-text-price {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: var(--input-border);
  font-weight: 700;

  &-le--dts {
    font-weight: 500 !important;
  }
}