

.quoteAccountModal{
  .generate-fake-data {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    margin-top: 0px;
  }

}