@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/legal_entities';

.changePassword {

  &-title-le {
    @include legalEntityPageTitleStyling();
  }

  &-context {
    margin-top: 1rem !important;
  }

  &-context-le {
    @include legalEntityPageContextStyling();
  }

  .passwordInput {
    margin-bottom: unset;
  }

  .repeatNewPwError,
  .currentPwError {
    margin-top: 5px;
    font-size: 16px;

    &-le {
      @include legalEntityInlineErrorStyling();
    }
  }

  .setPasswordSuccess {
    &-le {
      @include legalEntityInlineSuccessStyling();
    }
  }

  .currentPwError {
    margin-bottom: 1rem;
  }

  .currentPw {
    margin-bottom: unset;
  }

  &-actions {
    display: flex;
    justify-content: right;
    gap: .5rem;
  }
}
