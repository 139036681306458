@import "../../../sass/base/legal_entities";

.myContracts {

  display: flex;
  align-items: baseline;
  justify-content: space-between;


  &-title-le {
    @include legalEntityPageTitleStyling();
  }
  &-subtitle-le {
    @include legalEntityPageSubtitleStyling();
  }
}

.buttonGroup {
  display: flex;
  gap: 1rem;
}