@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/dimensions';
@import 'src/portal/sass/base/legal_entities';

.empty-state {
  text-align: center;
  padding-bottom: 2rem;

  &.boxStyling {
    @include card;
    padding: 3rem;
  }

  &-title {
    margin-bottom: .5rem;
  }

  &-icon {
    border-radius: 50%;
    padding: 1rem;
    margin-bottom: 1rem;
    &-le {
      @include legalEntityPrimaryFontColor();
      @include legalEntityIconBackgroundStyling();
    }
  }

  &-button {
    margin-top: 1rem;

    .buttonIcons {
      margin: 0 auto;
    }
  }

}