@import 'src/portal/sass/base/common';
@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/legal_entities';

.cpxButtonDropDownComponent {
  position: relative;


  .list {
    position: absolute;
    right: 0;
    margin: 0;
    list-style: none;
    font-size: 16px;
    padding-left: 0;
    min-width: 100%;

    &-le {
      @include legalEntityUserInfoComponentPrimaryStyling();
      @include legalEntityUserNavigationStyling();
      @include legalEntityBoxShadow();
    }

    button {
      @include buttonInDisguise;
      padding: 0 1rem;
      display: block;
      text-align: left;
      cursor: pointer;
      height: $height_languageButton;
      width: 100%;
      white-space: nowrap;
      font: var(--typo-body);
    }
  }
}
