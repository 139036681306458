@import 'src/portal/sass/base/legal_entities';
@import 'src/portal/sass/base/typography';


.subTitleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  .subText {
    margin-bottom: unset;

    &-le {
      @include legalEntitySubTitleFontStyling();
    }
  }

  .buttonIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    padding: 6px;
    border-radius: 2px;

    &-le {
      @include legalEntityButtonStylingNoBG();
    }

    p {
      padding: 8px;
    }
  }
}
