@import "../../portal/sass/base/colors";
@import "../../portal/sass/base/typography";
@import "../../portal/sass/base/dimensions";
@import 'src/portal/sass/base/legal_entities';

.inputWithLabel {
  margin-bottom: 1rem;
  position: relative;
  padding: 0;
  box-sizing: border-box;

  label {
    line-height: 1.25rem;
    font-size: $fontsize_small;
    margin-bottom: 4px;
    display: block;
  }

  .label {
    &-le {
      @include legalEntityLoginLabelStyling();
    }

    &.isHelpBox {
      display: flex;
      width: 30rem;
      justify-content: space-between;
    }

    .helpBox {
      .popup {
        min-width: 20rem;
        bottom: 2rem;
        right: .5rem;
      }
    }
  }

  .required {
    &.label-helpBox {
      &::after {
        content: "" !important;
      }
    }

    .input-helpBox {
      &::after {
        content: " *";
        margin-left: -5px;
      }
    }

    &::after {
      content: " *";
    }
  }

  .input {
    font-size: 16px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    line-height: 1.4;
    padding: $height_inputPadding $width_inputPadding;

    &-le {
      @include legalEntityInputStyling();
    }

    &:required ~ label::after {
      content: " *";
    }
  }

  .error {
    &-le {
      @include legalEntityInputBorderErrorStyling();
    }

    &:focus-visible {
      outline: none !important;
    }
  }
}
