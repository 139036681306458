@import '../../../../sass/base/legal_entities';
@import '../../../../sass/base/typography';

.partner-customer-contract {
  &-title-le {
    @include legalEntityPageTitleStyling();
  }
}

.subTitleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  .subText {
    margin-bottom: unset;
  }

  .buttonIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    padding: 6px;
    border-radius: 2px;

    p {
      padding: 8px;
    }
  }
}
