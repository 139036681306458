@import "src/portal/sass/base/dimensions";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/common";
@import "src/portal/sass/base/legal_entities";

@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.auth {
  width: 100%;
  max-width: $width_gridCol8;
  padding-left: $width_gridCol3 + $width_gridGap;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: all linear 0.1s;
  position: relative;
  animation: appear 1s ease-in-out forwards;
  &-le {
    @include legalEntityComponentPrimaryStyling();
    @include legalEntityAuthImage();
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: $width_loginImageMargin;
    bottom: 0;
    width: $width_gridCol3 + $width_gridGap - ($width_loginImageMargin * 2);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  p {
    margin-top: 0;
  }

  & > button {
    align-self: flex-end;
    margin-bottom: 2rem;
  }

  &.isLoading {
    opacity: 0.3;
  }
}
