@import 'src/portal/sass/base/colors';
@import 'src/portal/sass/base/legal_entities';
@import 'src/portal/sass/base/common';

.confirmExtensions {
  #modalTable {
    padding: unset;
    margin: 0px 0 34px 0;

    tbody {
      tr:last-child td.firstChild-le {
        padding-bottom: calc(#{$dimension_cellSpacing} / 2);
        @include legalEntityLastTableLinesStyling();
      }
    }

    .unit-price {
      &-le {
        @include legalEntityConfirmationModalStyling();
      }
    }

    td {
      @include hasIcon;

      &:nth-child(1) {
        width: 20% !important;
      }

      &:nth-child(2) {
        width: 45% !important;
      }

      &:nth-child(3) {
        width: 22%;
      }

      &:nth-child(4) {
        width: 19%;
      }
    }

    .paginatedTable {
      padding-top: 0;
    }

  }
}

.increaseInfo {
  display: flex;

  svg {
    g, path {
      fill: var(--card-title-color);
    }
  }

  svg {
    margin-right: 8px;
    width: 30px;
    height: 30px;
  }

  .infoTitle {
    margin-top: 4px;
  }
}

.reduceInfo {
  display: flex;
  margin-bottom: 64px;
  margin-top: 40px;

  g {
    fill: $color_warning;
  }

  svg {
    margin-right: 8px;
    width: 30px;
    height: 30px;
  }

  .in
  oReduce {
    width: fit-content;
  }

  .infoTitle {
    margin-top: 4px;

    color: var(--warning-color);
    font-weight: 700;
    &-le--dts {
      font-weight: 500 !important;
    }
  }
}

.amount-discount-wrapper {
  display: flex;
  gap: .5rem;
}

.line-through {
  text-decoration: line-through;
  opacity: 0.7;
}

.discountInfo {
  display: flex;
  gap: .5rem;
  align-items: center;

  svg {
    width: 27px;
    height: 30px;
    g {
      fill: white;
    }
  }
}