@import "../../../../sass/base/legal_entities";

.ostSummaryModal {
  &-total-sum {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid;
    padding: calc(1.5rem / 2) 0;
  }

  .line-through {
    text-decoration: line-through;
    opacity: 0.7;
  }

  &-voiceChannelModal {
    #ostSummaryTable {
      table tbody {
        tr {
          td {
            &:nth-child(1) {
              width: 18% !important;
            }

            &:nth-child(2) {
              width: 50% !important;
            }
          }
        }
      }
    }
  }
}

#ostSummaryTable > table > tbody > tr > td:nth-child(1) {
  &.firstChild-le {
    @include legalEntityModalOstSummaryTable();
  }
}

#ostSummaryTable {
  table {
    &.paginatedTable {
      padding-top: 0;
    }

    th:last-child {
      text-align: right;
    }

    tbody {
      tr {
        td {
          &:nth-child(1) {
            width: 0%;
            color: inherit;
          }

          &:nth-child(2) {
            width: 80% !important;
          }

          &:last-child {
            text-align: right;
          }

          &.firstChild-le {
            @include legalEntityModalOstSummaryTable();
          }
        }
      }
    }
  }
}
