@import "src/portal/sass/base/common";
@import "src/portal/sass/base/dimensions";
@import "src/portal/sass/base/colors";
@import "src/portal/sass/base/typography";
@import "src/portal/sass/base/legal_entities";

.toast {
  &-le {
    @include legalEntityToastErrorStyling();
  }

  > div {
    cursor: pointer;
  }
}

.toast-notification,
.isClickable {
  gap: .5rem;
  padding: 1.5rem 1rem !important;
  width: auto !important;
  max-width: none !important;
  font: var(--typo-h5);
  color: white !important;

  div {
    justify-content: left !important;
  }

  h5 {
    overflow-wrap: anywhere;
  }
}

.toast-with-button {
  display: flex;
  gap: 2rem;
}

.toast-success {
  background: var(--card-color) !important;
  border: 1px solid var(--button-disabled-bg-color) !important;

  &-le--dts {
    background: var(--primary-color) !important;
  }
}

.toast-error,
.isClickable {
  background: var(--error-color) !important;
  border: 1px solid var(--error-color) !important;
}

@keyframes customIn {
  0% {
    //transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    //transform: translateY(0);
    opacity: 1;
  }
}

@keyframes customOut {
  0% {
    //transform: translateY(0);
    opacity: 1;
  }
  100% {
    //transform: translateY(-100%);
    opacity: 0;
  }
}

#selectLockedContractModal {
  td {
    &:first-child {
      width: 12% !important;
    }

    &:nth-child(2) {
      width: 30% !important;
    }

    &:nth-child(3) {
      width: 30% !important;
    }

    &:last-child {
      button {
        width: 100%;
      }
      width: 20% !important;
    }
  }
}